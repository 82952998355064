import styled from 'styled-components'

export const LogoImageWrapper = styled.div`
        
//border: 1px solid blue;
display: flex;
margin: 0;
align-items: center;
justify-content: space-between;

img{  
    flex: 1 ;
        //border: 1px solid blue;    
        border-radius: 20px;
        width: 10%;
        
}   

//Para Tablet
@media (max-width: 1280px){
        //border: 1px solid blue;
        font-size: ${(props) => (props.rdt && props.rdt.toLowerCase().includes('si') ? '0.7em' : '0.7em')};      
        //display: none;
        margin:0;
                  
    }  

`;