import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useRdt } from "../../../hooks/useRdt";
import {
    IoAccessibility,
    IoRemoveCircleOutline,
    IoArrowBack,
    IoAddCircleOutline
} from "react-icons/io5";
import Alert from "../../../components/Alert/Alert";
import { AuthorizationsRdts } from "../../AddRdts/components/AuthorizationsRdts";
import { PropertyCardRdtsDuplicate } from "./PropertyCardRdtsDuplicate";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { Page } from "../../../components/Page";
import { PropertyBusinessType } from "../../../components/PropertyBusinessType";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { ButtonIconRemove } from "../../../components/ButtonIconRemove";
import { FormControlInputRadio, PageTitle } from "../../../globalStyles";
import {
    FormControl,
    FormControlSeccionOne,
    FormControlInput,
    FormControlInputSearch,
    PropertyTypesContainerColum,
    PropertyTypesContainerRow,
    RdtDetailDescriptionWrapper,
    RdtDetailInfoWrapper,
    RdtDetailSubTitle,
    RdtDetailValueWrapper,
    EmployeesValueWrapper,
    RdtDetailWrapper,
    RdtDetailWrapper_Global,
    RdtDetailWrapper_Global_DOS,
    LogoImageWrapper,
    FormControlActionTwo,
    RdtDetailValueWrapperdos,
    RdtDetailValueWrapperbutton,
    AddRdtsFill,
    AddRdtsFillnofound,
    FormControlSeccionTwo,
    PaginationContainer,
    PaginationButton
} from "./styles";

const ALL_SUCURSALES = 0;
const HORA = 10;
const itemsPerPage = 18;

export const RdtDuplicateDistribution = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload, rdtEmploye, setRdtEmploye } = useRdt();
    const { id } = useParams();

    //console.log({ rdtprovDuplicate: rdtprov.boardingStaff })
    const [alert, setAlert] = useState({})
    const [date, setDate] = useState('');
    const [rdt, setRdt] = useState([]);
    const [rdtstrabajador, setRdtstrabajador] = useState([]);
    const [rdtsAuth, setRdtsAuth] = useState([]);
    const [employe, setEmploye] = useState([]);
    const [codigoSeleted, setCodigoSelected] = useState('');
    const [employees, setEmployees] = useState([]);
    const [employeesall, setEmployeesall] = useState([]);
    const [employeesdials, setEmployeesdials] = useState([]);
    const [dial, setDial] = useState({})

    const [employeesdistribution, setEmployeesDistribution] = useState([]);
    const [employeesdistributionorg, setEmployeesDistributionOrg] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [modal, setModal] = useState(false);
    const [animarModal, setAnimarModal] = useState(false);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid
        }
    } = useForm({ mode: 'onChange' });

    //Validar dia de la semana
    const fechaComoCadena = date.split('T')[0] + " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const rango = new Date(hoy - fecha);
    const tiempo = new Date(189999999);
    //console.log({rango: rango})
    //console.log({tiempo: tiempo})

    useEffect(() => {
        // Transformar los datos
        console.log({ rdtprov: rdtprov })
        if(rdtprov.length > 0 || rdtprov.boardingStaff.length > 0){
            const transformedData = rdtprov.boardingStaff.flat();
            setEmployeesDistribution(transformedData); // Asignar arreglo transformado al estado       
            setEmployeesDistributionOrg(transformedData); // Asignar arreglo transformado al estado       
            console.log({ transformedData: transformedData })
        }else{
            console.log('No Tiene Boarding')
            //const transformedData = rdtprov.boardingStaff.flat();
            //setEmployeesDistribution(transformedData); // Asignar arreglo transformado al estado       
            //setEmployeesDistributionOrg(transformedData); // Asignar arreglo transformado al estado       
            //const test = Object.values(rdtprov.boardingStaff)              
            //setEmployeesDistribution(test); // Asignar arreglo transformado al estado             
            //console.log({distribution: test});
            console.log({ transformedData: rdtprov.boardingStaff })

        }    
    }, []); // Se ejecuta solo una vez en el montaje del componente
    //Rdt
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await getRdt()
                await getEmployeesAll()
                //await getEmployeeDial(); //Mensajes o comunicados al inicio                                                  
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [auth])
    //Rdts employee
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await getRdtstrabajador();
                await getEmployeesdialsAll();
                await getRdtsAuth();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [rdt])
    //Verificar Auth Finca
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                setDial([]);
                await verRdtsAuthFinca();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [rdtsAuth])
    //Employees
    useEffect(() => {
        const getDataEmpl = async () => {
            try {
                const token = getToken();
                if (!token) return
                await codigoSeleted ? handleEmployeCod() : cleanStatesEmployees();
                await verRdtsAuthFinca();
            } catch (error) {
                console.log(error)
            }
        }
        getDataEmpl();
    }, [codigoSeleted])
    useEffect(() => {
        const getDataEmpl = async () => {
            try {
                const token = getToken();
                if (!token) return
                await verRdtsAuthEmploye();
            } catch (error) {
                console.log(error)
            }
        }
        getDataEmpl();
    }, [employe])   
    //Ventana Modal para Autorizaciones
    const handleAutorizacionEmploye = () => {
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Valido si Fecha es mayor a la actual
        if (fecha > hoy) {
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Empleado        
        if (!employe[0]) {
            setAlert({
                msg: 'Se requiere codigo del trabajador para solicitar autorización de registro RDT',
                error: true
            })
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }

        const rdtData = {
            ibm: rdt.IbmLaboro,
            dateRdt: date.split('T')[0],
            nombreFinca: rdt.strFincaLaboro,
            nombreDia: nombreDia
        };
        setRdtprov(rdtData)
        setRdtEmploye(employe)
        setModal(true)

        setTimeout(() => {
            setAnimarModal(true)
        }, 300);
    }
    //Cod Labor
    const handleChangeCodLabor = (key) => {
        console.log({ key: key })
        setCodigoSelected(key)

    }
    //RdtById
    const getRdt = async () => {

        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: `/rdts/${id}`
                }
            );
            const { data } = response;
            data ? setRdt(data) : console.log('');
            data ? setDate(data.dtmFecha_RDT) : console.log('')

        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }

    }
    //getRdts
    const getRdtstrabajador = async () => {
        //console.log(rdt)
        if (rdt) {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts',
                        params: makePropertiesFilterstrabajador()
                    }
                );
                const { data } = response;
                //console.log(data)  
                await data[0] ? setRdtstrabajador(data) : console.log('');
            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
        } else {
            setRdtstrabajador([])
        }

    }
    const makePropertiesFilterstrabajador = () => {
        const filters = {};
        filters['Fecha_INI'] = rdt ? (rdt.dtmFecha_RDT).split('T')[0] : '';
        filters['Fecha_FIN'] = rdt ? (rdt.dtmFecha_RDT).split('T')[0] : '';
        filters['IntCodigo_EMPL'] = rdt ? rdt.IntCodigo_EMPL : '';
        filters['IbmLaboro'] = rdt ? rdt.IbmLaboro : '';
        //console.log({filtrotrab: filters});
        return filters;
    }
    //Employees Rdts Autorization without Dials
    const getRdtsAuth = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/rdtsauth',
                    params: makeRdtsAuthFiltersAll()
                }
            );
            const { data } = response;
            await setRdtsAuth(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeRdtsAuthFiltersAll = () => {
        const filters = {};
        filters['Fecha_INI'] = date;
        filters['Fecha_FIN'] = date;
        filters['IbmLaboro'] = rdt.IbmLaboro;
        filters['strEstado'] = 'Autorizado';
        return filters;
    }
    //EmployeesAll     
    const getEmployeesAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',
                    params: makeEmployeesFiltersAll()
                }
            );
            const { data } = response;
            setEmployeesall(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesFiltersAll = () => {
        const filters = {};

        /*if((auth.data.role !== 1) && (auth.data.role !== 2)){
            filters['Ibm'] = auth.data.ibm;
        }*/
        //console.log(filters);         
        return filters;
    }
    //Message for dial
    const getEmployeeDial = async () => {

        if (!dial[0]) {
            showAlert(
                '¡Información Importante!',
                'Informamos que, a partir del 3 de abril de 2023, el sistema no permitirá registrar los trabajadores sin marcación, para realizar el registro deberá solicitar autorización con su respectiva justificación al área encargada (Agricultura, Auditoria ó Nomina). Informa al administrador y coordinadores de la finca, acerca de este mensaje. Muchas Gracias por su atención...',
                SW_ICON.INFO,
                () => { FocusOnInput() }
            );
        }

        await setTimeout(() => {
            setAlert({})
        }, 7000)

    }
    //EmployeesDials
    const getEmployeesdialsAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employeesdials',
                    params: makeEmployeesdialsFiltersAll()
                }
            );
            const { data } = response;
            setEmployeesdials(data);
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesdialsFiltersAll = () => {
        const filters = {};
        filters['dtFecha'] = date;
        return filters;
    }
    //Employees
    const handleEmployeCod = () => {

        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                if ((auth.data.role !== 1) && (auth.data.role !== 2) && (auth.data.role !== 3)) {
                    showAlert(
                        'No Autorizado',
                        "Usuario no permitido para realizar esta operación",
                        SW_ICON.INFO
                    );
                    return
                }
                await getEmployeesCod();
            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
        }
        getData();
    }
    const getEmployeesCod = async () => {
        try {  /*          
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersCod()                                     
                }
            );             
            const {data} = response;              
            data ? setEmploye(data):console.log('')
            //await data ? getRdtstrabajador():cleanStatesConcepto();*/
            const data = (employeesall.filter(employe => employe.IntCodigo === codigoSeleted))//Filtrar trabajador            
            await data ? setEmploye(data) : console.log('');
            //await data?setDial(employeesdials.filter(employeDial => employeDial.strDocumento === employe[0].strDocumento)):console.log('');//Filtrar marcaciones trabajador           
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    //Verifico Auth Finca para realizar registros RDTs sin marcacion
    const verRdtsAuthFinca = async () => {
        await setDial(rdtsAuth.filter(farmAuth => farmAuth.IbmLaboro.includes(rdt.IbmLaboro) && farmAuth.strRequestby.includes('FINCA')))//Filtrar Autorizacion Finca
        return
        //console.log({Resultado: dial})
    }
    //Verifico Auth Employe para realizar registros RDTs sin marcacion
    const verRdtsAuthEmploye = async () => {
        if (!employe[0]) {
            return
        }
        if (rdtsAuth.length > 0) {
            //console.log('Tiene Autorizaciones');
            if (rdtsAuth[0].strRequestby !== 'FINCA') {
                //console.log('Verificando autorizaciones trabajador');
                const eAuth = rdtsAuth.filter(employeAuth => employeAuth.strDocumento.includes(employe[0].strDocumento))//Filtrar autorizaciones trabajador                 
                if (eAuth.length > 0) {
                    //console.log('Se encontro autorizacion para el trabajador..');
                    await setDial(eAuth)//marcacion del trabajador
                    //console.log(eAuth)
                } else {
                    //console.log('No se encontro auth y se Verifica marcaciones del trabajador..');
                    //1--Se valida reparto
                    await setDial(employeesdials.filter(employeDial => employeDial.strDocumento === employe[0].strDocumento))//Filtro marcacion del trabajador
                }
            } else {
                //console.log('Tiene Autorizacion toda la finca');
            }
        } else {
            //console.log('No Tiene Autorizaciones y consulto marcaciones...');
            //1--Se valida reparto
            await setDial(employeesdials.filter(employeDial => employeDial.strDocumento === employe[0].strDocumento))//Filtrar marcacion del trabajador
        }
        return
        //console.log({Resultado: dial})
    }
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Clean states employees
    const cleanStatesEmployees = () => {
        setEmploye([]);
        setDial([]);
    }
    //Add trabajador a la lista
    const handleAddemploye = () => {
        //Vallido que si este dentro de un rango permitido para adicionar
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    //if(rango > 363599999){ //Festivo dia lunes
                    setAlert({
                        msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Valido que sea un cod de trabajador activo
        if (!employe[0]) {
            setAlert({
                msg: 'Ingrese un codigo de trabajador valido',
                error: true
            })
            FocusOnInput();
            setTimeout(() => {
                setCodigoSelected('')
                setAlert({})
            }, 3000)
            return
        }
        //Valido si es oficinista
        if (rdt.IbmLaboro != auth.data.ibm && auth.data.role != 1) {
            setAlert({
                msg: 'Debe duplicar un registro de su misma finca',
                error: true
            })
            FocusOnInput();
            setTimeout(() => {
                setCodigoSelected('')
                setAlert({})
            }, 7000)
            return
        }
        /*
        //Valido que este incluido dentro del reparto
        const emplReparto = Object.values(rdtprov.boardingStaff).some(group => 
            group.some(persona => persona.IntCodigo === employe[0].IntCodigo)
        );
        if(!emplReparto){
            if (rdtsAuth.length > 0) {
                //console.log('Tiene Autorizaciones');
                if (rdtsAuth[0].strRequestby !== 'FINCA') {
                    //console.log('Verificando autorizaciones trabajador');
                    const eAuth = rdtsAuth.filter(employeAuth => employeAuth.strDocumento.includes(employe[0].strDocumento))//Filtrar autorizaciones trabajador                 
                    if (eAuth.length > 0) {
                        //console.log('Se encontro autorizacion para el trabajador..');
                        //await setDial(eAuth)//marcacion del trabajador
                        //console.log(eAuth)
                    } else {
                        //console.log('No se encontro auth del trabajador..');
                        setAlert({
                            msg: 'El trabajador no esta incluido en el reparto de la finca y no tiene autorización, para reportar RDT del día',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                } else {
                    //console.log('Tiene Autorizacion toda la finca');
                }
            } else {
                //console.log('No Tiene Autorizaciones y consulto marcaciones...');
                setAlert({
                    msg: 'El trabajador no esta incluido en el reparto de la finca y no tiene autorización, para reportar RDT del día',
                    error: true
                })
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
             
        }
        */
        //console.log({emplReparto: emplReparto})
        //Valido que trabajador tenga marcación en el dia
        if (!dial[0]) {
            setAlert({
                msg: 'Se requiere registro de marcación del trabajador para reportar RDT del día',
                error: true
            })
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }         
        //Valido que codigo no exista en el array
        const validateEmployees = employees.filter((empl) => empl.key === employe[0].IntCodigo);
        if (validateEmployees.length > 0) {
            setAlert({
                msg: 'Codigo del trabajador ya existe en la lista para ser duplicado',
                error: true
            })
            FocusOnInput();
            setTimeout(() => {
                setCodigoSelected('')
                setAlert({})
            }, 3000)
            return
        } else {
            const newEmploye = {
                key: employe[0].IntCodigo,
                IntCodigo_EMPL: employe[0].IntCodigo,
                strDocumento: employe[0].strDocumento,
                strNombreTrabajador: employe[0].strNombreTrabajador,
                strCodigo_SUCU: employe[0].Ibm,
                strNombre_SUCU: employe[0].strCodigoCentro,
                strNombre_EMPR: employe[0].Contratista,
            }
            setEmployees([...employees, newEmploye])
            setCodigoSelected('')
            FocusOnInput();

            setEmployeesDistribution((prev) => prev.filter((i) => i.IntCodigo !== employe[0].IntCodigo)); // Eliminar de currentItems
        }
        //console.log({employees:employees})  

    }
    //Delete trabajador de la lista
    const handleRemoveemploye = (key) => {
        //console.log({key: key})
        const newsEmployees = employees.filter((empl) => empl.key !== key);
        //console.log({keNewy: newsEmployees})
        setEmployees(newsEmployees)
        const reintEmpl = employeesdistributionorg.filter((empl) => empl.IntCodigo == key)
        //console.log({ reintEmpl: reintEmpl });
        setEmployeesDistribution((prev) => [...prev, reintEmpl[0]]); // Agregar de vuelta a currentItems      

    }
    //Duplicate
    const handleAddDuplicate = async (data) => {
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    //if(rango > 363599999){ //Festivo dia lunes
                    setAlert({
                        msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede duplicar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //HORA--Valido Fecha para poder realizar registros antes de la 13pm
        const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0 indexados
            const year = date.getFullYear();
            /*
            // Formatear hora            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            console.log({hours: hours})
            */


            return `${year}-${month}-${day}`;
            //return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        };
        let currentDate = formatDate(Date.now());
        //console.log({ currentDate: currentDate })
        //console.log({ date: date })
        if (date < currentDate) {
            //Valido hora
            const formatDatehour = (timestamp) => {
                const date = new Date(timestamp);
                //Formatear hora            
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                //console.log({hours: hours});         
                return `${hours}`;
            };
            let currentDateHour = formatDatehour(Date.now());

            //Hora para grabar debe ser menor a la 13 de la tarde.
            if (currentDateHour > 13) {
                showAlert(
                    'No Permitido',
                    "Señor usuario recuerde que solo se permite registrar RDTs antes de la 1pm",
                    SW_ICON.ERROR
                );
                return
            }
        }
        //console.log(data.checkbox.length)
        //console.log(data.checkbox)
        if (!data.checkbox.length) {
            setAlert({
                msg: 'Seleccione las labores a duplicar',
                error: true
            })
            FocusOnInput();
            setTimeout(() => {
                setCodigoSelected('')
                setAlert({})
            }, 7000)
            return
        }
        if (!employees.length) {
            setAlert({
                msg: 'Ingrese los codigos de los trabajadores a duplicar',
                error: true
            })
            FocusOnInput();
            setTimeout(() => {
                setCodigoSelected('')
                setAlert({})
            }, 7000)
            return
        }
        for (let b = 0; b < employees.length; b++) {
            //console.log(employees[i])
            for (let i = 0; i < data.checkbox.length; i++) {
                //console.log(rdtstrabajador[data.checkbox[i]])

                const Observation = employeesdistributionorg.filter(
                    (empl) => String(empl.IntCodigo).trim() === String(employees[b].IntCodigo_EMPL).trim()
                );

                //console.log("Observation:", Observation, "Empleado:", employees[b]);

                const fecha = Date.now();
                const dataRdt = {};
                dataRdt['IntCodigo_EMPL'] = employees[b].IntCodigo_EMPL;
                dataRdt['strDocumento'] = employees[b].strDocumento;
                dataRdt['strNombreTrabajador'] = employees[b].strNombreTrabajador;
                dataRdt['strCodigo_SUCU'] = employees[b].strCodigo_SUCU;
                dataRdt['strNombre_SUCU'] = employees[b].strNombre_SUCU;
                dataRdt['strNombre_EMPR'] = employees[b].strNombre_EMPR;
                dataRdt['IntCodigo_CONC'] = rdtstrabajador[data.checkbox[i]].IntCodigo_CONC;
                dataRdt['strConcepto'] = rdtstrabajador[data.checkbox[i]].strConcepto;
                dataRdt['intHora_RDT'] = rdtstrabajador[data.checkbox[i]].intHora_RDT;
                dataRdt['intTotal_RDT'] = rdtstrabajador[data.checkbox[i]].intTotal_RDT;
                dataRdt['dtmFecha_RDT'] = rdt.dtmFecha_RDT;
                dataRdt['IbmLaboro'] = rdt.IbmLaboro;
                dataRdt['strFincaLaboro'] = rdt.strFincaLaboro;

                if (Observation.length > 0) {
                    dataRdt['strObservacion_RDT'] = Observation[0].reparto;
                } else {
                    //console.warn("No match found for:", employees[b].IntCodigo_EMPL);
                    dataRdt['strObservacion_RDT'] = rdtstrabajador[data.checkbox[i]].strObservacion_RDT;
                }

                dataRdt['email_USUA'] = auth.data.email;
                dataRdt['dtmFechaCrea'] = fecha;
                dataRdt['dtmFechaModifica'] = fecha;
                dataRdt['employeesId'] = auth.data._id;

                dataRdt['IntCodigo_UDP'] = rdtstrabajador[data.checkbox[i]].IntCodigo_UDP;
                dataRdt['strNombre_UDP'] = rdtstrabajador[data.checkbox[i]].strNombre_UDP;
                dataRdt['intCantidad_UDP'] = rdtstrabajador[data.checkbox[i]].intCantidad_UDP;

                dataRdt['IntCodigo_UDPdos'] = rdtstrabajador[data.checkbox[i]].IntCodigo_UDPdos;
                dataRdt['strNombre_UDPdos'] = rdtstrabajador[data.checkbox[i]].strNombre_UDPdos;
                dataRdt['intCantidad_UDPdos'] = rdtstrabajador[data.checkbox[i]].intCantidad_UDPdos;

                dataRdt['IntCodigo_UDPtres'] = rdtstrabajador[data.checkbox[i]].IntCodigo_UDPtres;
                dataRdt['strNombre_UDPtres'] = rdtstrabajador[data.checkbox[i]].strNombre_UDPtres;
                dataRdt['intCantidad_UDPtres'] = rdtstrabajador[data.checkbox[i]].intCantidad_UDPtres;

                dataRdt['IntCodigo_UDPcuatro'] = rdtstrabajador[data.checkbox[i]].IntCodigo_UDPcuatro;
                dataRdt['strNombre_UDPcuatro'] = rdtstrabajador[data.checkbox[i]].strNombre_UDPcuatro;
                dataRdt['intCantidad_UDPcuatro'] = rdtstrabajador[data.checkbox[i]].intCantidad_UDPcuatro;
                sendDataRdt(dataRdt)
                //console.log({RDTDuplicado: dataRdt})
            }
        }

        showAlert(
            'Duplicado',
            "Correctamente",
            SW_ICON.SUCCESS,
            () => { FocusOnInput() }
        );

    }
    const sendDataRdt = async (dataRdt) => {

        try {
            const response = await requestHttp(
                {
                    endpoint: '/rdts',
                    body: dataRdt
                }
            );
        } catch (error) {
            //setIterations(false)            
            /*console.log(dataRdt.IntCodigo_EMPL,dataRdt.IntCodigo_CONC) 
            await setEmplInconsistencia({Empl: dataRdt.IntCodigo_EMPL, CONC: dataRdt.IntCodigo_CONC})       */
            showAlert('Error', `"Duplicado Incorrecto"`, SW_ICON.ERROR);
        }

    }
    //focus para ir agregando
    function FocusOnInput() {
        document.getElementById("input1").focus();
    }
    // Colección de inputs con índices de (0 a 3)
    // 0 es la posición de #input1, 3 es la posición de #input4
    let inputs = document.querySelectorAll('input2');
    // Asignar evento para ejecutar función
    inputs.forEach(input => input.addEventListener('keypress', nextFocus));

    // Recibe evento como parámetro
    function nextFocus(e) {
        //console.log('Presente ENTER')               

    }
    // Calcular los datos de la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = employeesdistribution.slice(indexOfFirstItem, indexOfLastItem);

    // Calcular el total de páginas
    const totalPages = Math.ceil(employeesdistribution.length / itemsPerPage);

    // Función para cambiar de página
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    //Message
    const { msg } = alert;

    //console.log({ employeesdistribution: employeesdistribution });
    //console.log({ DuplicateEmployees: employees })
    //console.log({ currentItems: currentItems })
    //console.log({ totalPages: totalPages })

    return (
        <Page>
            <RdtDetailWrapper_Global>
                <RdtDetailWrapper_Global_DOS>
                    <LogoImageWrapper>
                        <PageTitle>
                            DUPLICAR RDTs
                        </PageTitle>
                        <img src={"../Logodos.jpg"} alt="imgLogo" />
                    </LogoImageWrapper>

                    <RdtDetailWrapper>
                        <RdtDetailInfoWrapper>
                            <form onSubmit={handleSubmit(handleAddDuplicate)}>
                                <h2>{rdt.strNombreTrabajador}</h2>
                                <RdtDetailSubTitle>
                                    {rdt.strNombre_SUCU}
                                </RdtDetailSubTitle>
                                <h3>Cod Trabajador: {rdt.IntCodigo_EMPL} </h3>
                                <RdtDetailDescriptionWrapper>
                                    <PropertyTypesContainerRow>
                                        <PropertyTypesContainerColum>
                                            <RdtDetailValueWrapper>
                                                Laboró en:
                                                <h2>{rdt.strFincaLaboro}</h2>
                                                <h3>Ibm: {rdt.IbmLaboro} </h3>
                                                <PropertyBusinessType
                                                    busineesType={`Fecha del RDT:`}
                                                />
                                                <RdtDetailValueWrapperdos>
                                                    <p>{rdt.dtmFecha_RDT ? rdt.dtmFecha_RDT.split('T')[0] : ''} <span>({nombreDia})</span></p>
                                                </RdtDetailValueWrapperdos>
                                            </RdtDetailValueWrapper>
                                            <RdtDetailValueWrapperbutton>
                                                <p><ButtonIcon icon={IoAddCircleOutline} onPress={handleAddemploye} id="input2" /></p>
                                                <h2>Reg: {employees.length + 1}</h2>
                                                <p><input type="submit" value="DUPLICAR" /></p>
                                            </RdtDetailValueWrapperbutton>
                                        </PropertyTypesContainerColum>
                                        <PropertyTypesContainerColum>
                                            Seleccione labores a duplicar:
                                            <RdtDetailValueWrapper>
                                                {rdtstrabajador.map((item, key) =>
                                                    <FormControlInputRadio>
                                                        <input {...register("checkbox")} type="checkbox" value={key} />
                                                        <PropertyCardRdtsDuplicate
                                                            key={item._id}
                                                            {...item}
                                                            propertyTypeSeleted

                                                        />
                                                    </FormControlInputRadio>
                                                )
                                                }
                                            </RdtDetailValueWrapper>
                                        </PropertyTypesContainerColum>
                                    </PropertyTypesContainerRow>
                                </RdtDetailDescriptionWrapper>
                            </form>
                        </RdtDetailInfoWrapper>
                    </RdtDetailWrapper>
                    {msg && <Alert alert={alert} />}
                    <EmployeesValueWrapper>
                        <div class="box">
                            {currentItems?.map((empl, key) =>
                                <div class="subBox">
                                    <ButtonIconRemove icon={IoAddCircleOutline} onPress={() => handleChangeCodLabor(empl.IntCodigo)} />
                                    <label>{empl.IntCodigo}</label>
                                </div>
                            )}
                        </div>
                        {/* Paginación */}
                        <PaginationContainer>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <PaginationButton
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    active={currentPage === index + 1}
                                >
                                    {index + 1}
                                </PaginationButton>
                            ))}
                        </PaginationContainer>
                    </EmployeesValueWrapper>
                    <FormControlSeccionOne>
                        <FormControl>
                            <FormControlInputSearch>
                                <label>COD. TRABAJADOR</label>
                                <input type="number"
                                    {...register("codigoSearch")}
                                    value={codigoSeleted}
                                    onChange={e => setCodigoSelected(e.target.value)}
                                    autoFocus
                                    tabIndex="3"
                                    id="input1"
                                />
                            </FormControlInputSearch>
                            <FormControlInput>

                            </FormControlInput>
                            <FormControlInput>
                                <label>CODIGO</label>
                                <p {...register("codigo")}>{employe[0] ? employe[0].IntCodigo : ''}</p>
                            </FormControlInput>
                        </FormControl>
                        <FormControl>
                            <FormControlInput>
                                <label>NOMBRE DEL TRABAJADOR</label>
                                {dial[0] ?
                                    <AddRdtsFill>
                                        <p {...register("name")}>{employe[0] ? employe[0].strNombreTrabajador : ''}</p>
                                    </AddRdtsFill> :
                                    <AddRdtsFillnofound>
                                        <p {...register("name")}>{employe[0] ? employe[0].strNombreTrabajador : ''}</p>
                                        <ButtonIcon icon={IoAccessibility} onPress={handleAutorizacionEmploye} />
                                        {modal && <AuthorizationsRdts
                                            setModal={setModal}
                                            animarModal={animarModal}
                                            employe={employe}
                                        />
                                        }
                                    </AddRdtsFillnofound>}
                            </FormControlInput>
                        </FormControl>

                        <FormControl>
                            <FormControlInput>
                                <label>FINCA A LA QUE PERTENECE</label>
                                <p  {...register("origen")}>{employe[0] ? employe[0].strCodigoCentro : ''}</p>
                            </FormControlInput>
                        </FormControl>
                    </FormControlSeccionOne>
                    <EmployeesValueWrapper>
                        <div class="box">
                            {employees.map((empl, key) =>
                                <div class="subBox">
                                    <ButtonIconRemove icon={IoRemoveCircleOutline} onPress={() => handleRemoveemploye(empl.key)} />
                                    <label>{empl.IntCodigo_EMPL}</label>
                                </div>
                            )}
                        </div>
                    </EmployeesValueWrapper>
                    <FormControlActionTwo>
                        <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/Rdts') }} />
                    </FormControlActionTwo>
                </RdtDetailWrapper_Global_DOS>
            </RdtDetailWrapper_Global>
        </Page>
    )
}