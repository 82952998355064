import styled, {css} from "styled-components";

export const FormControlModal = styled.div` 
    //border: 4px solid orange;   
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: rgb(0 0 0 / 0.92);
    top: 18px;
    left: 0;
    right: 0;
    bottom: 0;
    
`;

export const CerrarModal = styled.div`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    //width: 2rem;
    //height: 2rem;
`;

export const FormularioModal = styled.div`
    //border: 1px solid blue;      
    //max-width: 80rem;
    //width: 100%;
    //padding: 300px;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;     
    opacity: 1;

    @media (max-width: 1024px){
            //border: 1px solid blue; 
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            //width: 100%;            
    } 
    @media (min-width: 1024px){
            //border: 1px solid blue; 
            margin-top: 60px;
            padding: 0 20px;            
            justify-content: center;
            //width: 50%;
    }
    
`;

export const AnimarAuthorizationsRdt = styled.div` 
    opacity: 1;
`;

export const TittleModal = styled.h1`
    font-size: 1em;
    //color: #222;
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    border: 1px solid blue;
    //width: 100%;

    @media (max-width: 820px){
            font-size: 0.5em;
            margin-top: 100px;
            margin-bottom: 0px;
            padding: 0 2px;            
            justify-content: center;
            width: 100%;
    }    
`;

export const AuthorizationsRdtDetailWrapper = styled.div`
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;
    background-color:white;
    text-decoration: none;    
`;

export const AuthorizationsRdtDetailInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    font-size: 1em;
    h2{
        font-size: 1.5em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }        
`;

export const AuthorizationsRdtDetailSubTitle = styled.div`
    //border: 1px solid red;
    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;
`;

export const ModalDetailDescriptionWrapper = styled.div` 
    border-radius: 20px;
    color: #6262A0;
    font-weight: bold; 
    font-size: 0.8em;   
    padding-bottom: 10px;    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const ModalPropertyTypesContainerRow = styled.section`
    //border: 1px solid red;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 10px 0;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;   
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);   
`;

export const ModalPropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    
    border-radius: 20px;
    background-color: white;
    padding: 15px;
    width: 100%;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    }
`;

export const ModalDetailValueWrapper = styled.div`
    color: #222;
    font-size: 1em;
    h2{
        font-size: 1.2em;
        margin: 0;
        color:#6262A0;
    }
    h3{        
        margin: 0;
        color:#6262A0;
    }
`;

export const PropertyTableContainer = styled.div`
  //max-height: 920px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura máxima */
  position: relative; /* Necesario para el posicionamiento relativo del encabezado */
  margin-left: 10px;
`;

export const PropertyTable = styled.table`
  width: auto;
  margin-top: 5px;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const PropertyTableHeader = styled.th`
  background-color: #26794f;
  color: white;
  text-align: center;
  padding: 4px;
  //position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      padding: 3px;
      background-color: #a0d995;
      color: black;
      //text-decoration-line: underline;
      //font-weight: bold;
    }
`;

export const PropertyTableHeaderRow = styled.tr`
  background-color: #b0c2f2;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  font-size: 14px;
`;

export const PropertyTableRow = styled.tr`
  &:nth-child(even) {
    background-color: white;
  }  
  font-size: 10px;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCellRigth = styled.td`
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;  
  color: black;
  //font-weight: bold;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      //color: blue;
      font-weight: bold;
      text-align: center; 
    }
    P{
      text-align: left;
      font-weight: bold;
    }
`;

export const PropertyTableCell = styled.td`
  border: 1px solid #ddd;  
  padding: 0;
  text-align: center;
  color: black;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 0;
        font-size: 0.8rem;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      color: blue;
      font-weight: bold;
    }
    P{
      font-weight: bold;
    }
`;
export const PropertyTableCellTotal = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: right;
  color: black;
  font-weight: bold;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: blue;
    //text-decoration-line: underline;
    font-weight: bold;
    //background-color: #4A148C;
    background-color: transparent;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: right;
    border: none;
    //border-radius: 6px;
    //padding: 5px 5px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    }   
`;

export const ButtonAplicate= styled.button`
    ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }    
`;

export const PropertyApp = styled.div`
    //border: 1px solid red;
    display: flex; 
    flex-wrap: wrap; // Permitir que los elementos se acomoden si es necesario
    gap: 10px; // Espacio entre los elementos
    margin: 5px;
    padding: 20px;
    width: 100%; // Ocupa todo el ancho disponible
    justify-content: space-around; // Distribuir espacio entre elementos

    // Estilo de los elementos hijos (los 5 objetos)
    > * {
        flex: 1 1 calc(20% - 10px); // Cinco elementos en una fila en pantallas grandes
        max-width: calc(20% - 10px); // Cada uno ocupa 20% del ancho menos el gap
        box-sizing: border-box;
    }

    // Pantallas medianas (3 columnas)
    @media (min-width: 981px) and (max-width: 1180px) {
       
        > * {
            flex: 1 1 calc(33.33% - 10px); // Tres columnas
            max-width: calc(33.33% - 10px);
        }
    }

    // Pantallas pequeñas (2 columnas)
    @media (max-width: 980px) {
        > * {
            flex: 1 1 calc(50% - 10px); // Dos columnas
            max-width: calc(50% - 10px);
        }
    }

    // Móviles (1 columna)
    @media (max-width: 512px) {
        
        > * {
            flex: 1 1 100%; // Una columna en móviles
            max-width: 100%;
        }
    }
`;
export const PropertyWorkerslistGroup = styled.div`
    border: 1px solid red;
    font-size: 0.6em;
    //width: 400px;
    //max-height: 600px;
    overflow-y: auto;
    border: 2px solid #ccc;
    padding: 5px;
    border-radius: 8px;
    //Para Tablet
    @media (max-width: 1366px){
        //border: 1px solid blue;
        //top: 2rem;   
        width: 90%;          
    }
    //Para moviles
    @media (max-width: 512px){
        font-size: 0.6em;
        width: auto;
        max-height: auto;
        overflow-y: auto;
        border: 2px solid #ccc;
        padding: 5px;
        border-radius: 8px;
        
    }

    
    h2{
        //border: 1px solid red;
        //color:white;
        background-color: white;
        text-align: center;
        //padding: 2px;
        span{
            padding-left: 15px;
            color: blue;
        }
    }
    button{           
        background-color: #f0a808;
        border: 1px solid #f0a808;
        margin-left: 10px;
        font-size: 1em;
    }
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;    
        button{
            cursor: pointer;                    
        }
    }
`;
export const PropertyWorker = styled.div`
    margin: 5px 0;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 4px;
    text-align: center;

    &:hover{        
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff};      
    }
    
`;
export const PropertyGroup = styled.div`
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 8px;
    //margin-bottom:3px;
    

`;
export const PropertysubGroup = styled.div`
    border: 3px solid #ccc;
    background-color: whitesmoke;
    padding: 5px;

    h2{
        text-align:center;
    }
`;
export const PropertyItem = styled.div`
    //border: 1px solid blue;  
    
    display: flex;
    justify-content: space-between;  
    align-items: left;
    font-size: 0.9em;
    padding: 0 0 0 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};

    h3{
        padding: 5px 0 0px 5px;
    }
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
    button{           
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#f0a808')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '1px solid #f0a808')};
        margin: 0.5px;
        font-size: 1em;        
    }    
`;
export const PropertyItemLab = styled.div`
    //border: 1px solid orange;  
    
    display: flex;
    flex-direction: row;
    //justify-content: space-between;  
    align-items: right;
    font-size: 0.9em;
    padding-left: 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
    button{           
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#f0a808')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '1px solid #f0a808')};
        margin: 0.5px;
        font-size: 1em;        
    }    
`;
export const PropertyItemRdt = styled.div`

    //border: 1px solid red;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    width:100%;
    align-items: left;
    //text-align: center;
    font-size: 1em;
    margin-right: 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: blue;                  
        }
    }
    button{    
        color: white;       
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#808080')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '1px solid #808080')};
        margin-right: 3px;
        font-size: 1em;   
        //border: 1px solid red;
       
    }
    span{
        background-color: blue;
    }
`;
export const PropertyItemButton = styled.div`
    //border: 1px solid red;
    h3{
        padding: 5px 0 0px 5px;
    }
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        //background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
        
    button{         
        padding-left: 5px;          
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#808080')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '4px solid #f9f9f9')};
        color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'blue' : 'white')};
        margin: 0.5px;
        font-size: 1em;   
        
        .tooltip {
            visibility: hidden;
            background-color: black;
            color: white;
            text-align: center;
            padding: 5px;
            border-radius: 5px;
            position: absolute;
            bottom: 125%; /* Posiciona el tooltip arriba del botón */
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            z-index: 1;
        }
    }

`;

export const PropertyInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    h3{
        margin: 0;
        color:#6262A0;
        font-size: 1.2em;
    }    
`;

