import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { PropertyCardRdts } from "./PropertyCardRdts";
import {
  PropertyButtonWrapper, PropertyCardWrapper,
  PropertyCardWrapperTr,
  PropertyDescriptionWrapper,
  PropertyImageWrapper,
  PropertyInfoWrapper,
  PropertyValueWrapper
} from "./PropertyCardRdts/styles";
import { FormControl, FormControlInput, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import {
  PropertyTypesContainerColum,
  PropertyTypesContainerHead,
  PropertyTypesContainerRow,
  RdtsDetailTitle,
  RdtsWrapper,
  RdtsDetailInfo
} from "./styles";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import {
  IoTrashOutline,
  IoRemoveCircleOutline,
  IoRadioButtonOffOutline,
  IoArrowBackCircleOutline,
  IoArrowBack,
  IoDuplicate,
  IoAddCircleOutline,
  IoSave,
  IoSend
} from "react-icons/io5";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"

const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0;

export const Rdts = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { rdtprov, setRdtprov } = useRdt();
  const [rdts, setRdts] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);

  const [codtrabajador, setCodtrabajador] = useState('');
  const [codlabor, setCodlabor] = useState('');
  const [codudp, setCodudp] = useState('');
  const [fechaini, setFechaIni] = useState('');
  const [fechafin, setFechafin] = useState(fecha);
  const [date, setDate] = useState('');

  const [sumtotal, setSumtotal] = useState(suma);
  const [sumcant, setSumcant] = useState(suma);
  const [sumcantdos, setSumcantdos] = useState(suma);
  const [sumcanttres, setSumcanttres] = useState(suma);
  const [sumcantcuatro, setSumcantcuatro] = useState(suma);
  const [sumhoras, setSumhoras] = useState(suma);

  const [alert, setAlert] = useState({})

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid
    }
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const getData = async () => {
      try {
        const token = getToken();
        if (!token) return
        await getSucursalesAll();
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [auth])

  //Si estoy editando
  useEffect(() => {
    const getData = async () => {
      try {
        const token = getToken();
        if (!token) return
        await rdtprov.ibm ? setSucursalSelected(rdtprov.ibm) : console.log('');
        await rdtprov.dateRdt ? setFechaIni((rdtprov.dateRdt).split('T')[0]) : console.log('');
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [rdtprov])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdtsUdp();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [sucursalSeleted])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdtsUdp();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [fechaini])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdtsUdp();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codtrabajador])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        await getRdtsUdp();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codlabor])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdtsUdp();

      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codudp])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        await onSubmitCantidad();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [rdts])

  //Sucursales
  const handleChange = event => {
    setSucursalSelected(event.target.value)
  }
  const handleChangeDate = event => {
    setFechaIni(event.target.value)
  }
  const handleChangeCodtrabajador = event => {
    setCodtrabajador(event.target.value)
  }
  const handleChangeCodlabor = event => {
    setCodlabor(event.target.value)
  }
  const handleChangeCodudp = event => {
    setCodudp(event.target.value)
  }
  //Sucursals
  const getSucursalesAll = async () => {
    try {
      const response = await requestHttp(
        {
          method: HTTP_VERBS.GET,
          endpoint: '/sucursal',
          params: makeSucursalesFilters()
        }
      );
      const { data } = response;
      setSucursal(data);
    } catch (error) {
      console.log(error)
    }
  }
  const makeSucursalesFilters = () => {
    const filters = {};
    return filters;
  }
  //Close session if token expired
  const hendleCloseSeccion = async () => {
    closeSeccionAuth()
    removeToken()
    showAlert(
      'Inicie Seccion Nuevamente',
      "Su token puede estar vencido",
      SW_ICON.INFO,
      () => { navigate('/Login') }
    );
  }
  //getRdts
  const getRdts = async () => {
    //console.log({sucursal: sucursalSeleted})
    if (!fechaini) {
      setRdts([])
      return
      //console.log('Fecha vacia')
    }
    if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    } else {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/Rdts',
            params: makePropertiesFilters()
          }
        );
        const { data } = response;
        data ? setRdts(data) : console.log('');
      } catch (error) {
        if (error.response.status === 401) {
          hendleCloseSeccion();
        } else {
          console.log(error)
        }
      }
      //console.log({hacerConsulta: sucursalSeleted})        
    }
  }
  //getRdts
  const getRdtsUdp = async () => {
    //console.log('consulta desde UDP')
    if (!fechaini) {
      setRdts([])
      return
      //console.log('Fecha vacia')
    }
    if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    } else {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/Rdts/analyses',
            params: makePropertiesFilters()
          }
        );
        const { data } = response;
        data ? setRdts(data) : console.log('');
      } catch (error) {
        if (error.response.status === 401) {
          hendleCloseSeccion();
        } else {
          console.log(error)
        }
      }
      //console.log({hacerConsulta: sucursalSeleted})        
    }
  }
  //Sumar cantidad
  const onSubmitCantidad = async (data) => {
    if (Object.keys(rdts).length > 0 && Object.keys(codlabor).length > 0 || Object.keys(codudp).length > 0) {
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
      for (let i = 0; i < rdts.length; i++) {
        a = a + rdts[i].intTotal_RDT;
        b = b + rdts[i].intCantidad_UDP;
        c = c + rdts[i].intCantidad_UDPdos;
        d = d + rdts[i].intCantidad_UDPtres;
        e = e + rdts[i].intCantidad_UDPcuatro;
      }
      setSumtotal(a)
      setSumcant(b)
      setSumcantdos(c)
      setSumcanttres(d)
      setSumcantcuatro(e)
      setSumhoras(0)
    } else if (Object.keys(rdts).length > 0 && Object.keys(codtrabajador).length > 0) {
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
      for (let i = 0; i < rdts.length; i++) {
        a = a + rdts[i].intTotal_RDT;
        b = b + rdts[i].intCantidad_UDP;
        c = c + rdts[i].intTotal_RDT;
        d = d + rdts[i].intTotal_RDT;
        e = e + rdts[i].intHora_RDT;
      }
      setSumtotal(0)
      setSumcant(0);
      setSumcant(0)
      setSumcant(0)
      setSumcant(0)
      setSumhoras(e)
    } else {
      setSumtotal(0);
      setSumcant(0);
      setSumcant(0)
      setSumcant(0)
      setSumcant(0)
      setSumhoras(0);
    }

  }
  const makePropertiesFilters = () => {
    const filters = {};
    filters['Fecha_INI'] = fechaini;
    filters['Fecha_FIN'] = fechaini;
    filters['IbmLaboro'] = sucursalSeleted;
    filters['IntCodigo_EMPL'] = codtrabajador;
    filters['IntCodigo_CONC'] = codlabor;
    filters['IntCodigo_UDP'] = codudp;
    // filters['employeesId'] = auth.data._id;     
    //console.log(fechaini);
    return filters;
  }
  //Delete RDT
  const showAlertYN = (id) => {
    //Validar dia de la semana
    const fechaComoCadena = fechaini ? fechaini.split('T')[0] + " 00:01:59" : console.log(''); // día semana  
    const fecha = new Date(fechaComoCadena);
    const numeroDia = new Date(fechaComoCadena).getDay();
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const rango = new Date(hoy - fecha);
    const tiempo = new Date(189999999);

    //console.log({rango: rango})
    //console.log({tiempo: tiempo})

    //Validaciones
    //Vallido que si este dentro de un rango permitido para eliminar
    //valido si dia es viernes o sabado                          
    if (numeroDia >= 5) {
      //rango para el viernes de 4 dias.
      if (numeroDia == 5) {
        if (rango > 363599999) {
          setAlert({
            msg: 'Usted ya no puede eliminar registros de RDT para este dia',
            error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(() => {
            setAlert({})
          }, 7000)
          return
        }
      } else {
        //rango para el sabado de 3 dias.                     
        if (rango > 276999999) {
          //if(rango > 363599999){ //Festivo dia lunes
          setAlert({
            msg: 'Usted ya no puede eliminar registros de RDT para este dia',
            error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(() => {
            setAlert({})
          }, 7000)
          return
        }
      }
    } else {
      //Rango de 2 dias para registrar
      if (rango > 189999999) {
        setAlert({
          msg: 'Usted ya no puede eliminar registros de RDT para este dia',
          error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(() => {
          setAlert({})
        }, 7000)
        return
      }
    }
    //console.log({id: id})
    swal({
      title: "Esta usted seguro?",
      text: "Una vez eliminado, no podras recuperar este registro!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          rdtDelete(id)
        } else {
          //swal("Su registro esta a salvo!");
          return
        }
        //console.log(willDelete)
      });
  }
  const rdtDelete = async (id) => {
    try {
      const response = await requestHttp(
        {
          method: HTTP_VERBS.DELETE,
          endpoint: `/rdts/${id}`,
          token: getToken()
        }
      );
      getRdts()
      showAlert(
        'RDT',
        "Eliminado Correctamente",
        SW_ICON.SUCCESS
      );

    } catch (error) {
      if (error.response.status === 401) {
        //hendleCloseSeccion();
      } else {
        showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
      }
    }
  }

  //Validar dia de la semana
  const fechaComoCadena = fechaini.split('T')[0] + " 00:01:59"; // día semana 
  const dias = [
    'DOMINGO',
    'LUNES',
    'MARTES',
    'MIERCOLES',
    'JUEVES',
    'VIERNES',
    'SABADO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];

  const { msg } = alert;

  return (
    <Page>
      <RdtsDetailTitle>
        CONSULTA DE RDTS
      </RdtsDetailTitle>
      {msg && <Alert alert={alert} />}
      <form>
        <PropertyTypesContainerRow>
          <PropertyTypesContainerColum>
            <LogoImageWrapper>
              <img src={"Logodos.jpg"} alt="imgLogo" />
            </LogoImageWrapper>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <FormControl>
              <FormControlInput>
                <label>FINCA REPORTADA</label>
                <select id="sucursal"
                  {...register("sucursal", { required: true })}
                  onChange={handleChange}
                  value={sucursalSeleted}
                  disabled={auth.data.role != 1 && rdtprov.ibm != 90021 ? true : false}>
                  <option value={""}>--Seleccione--</option>
                  {sucursal.map(item => (
                    <option key={item.strCodigo}
                      value={item.strCodigo}
                      id={item.strCodigo}
                    >{item.strNombre}</option>
                  )
                  )
                  }

                </select>
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>FECHA DEL RDT</label>
                <input type="date"
                  {...register("fechaini")}
                  onChange={handleChangeDate}
                  value={fechaini}
                />
                {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
              </FormControlInput>
            </FormControl>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <FormControl>
              <FormControlInput>
                <label>COD.TRABAJDOR:</label>
                <input type='text'
                  {...register("codtrabajador")}
                  onChange={handleChangeCodtrabajador}
                />
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>COD.LABOR:</label>
                <input type='text'
                  {...register("codlabor")}
                  onChange={handleChangeCodlabor}
                />
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>UDP:</label>
                <input type='text'
                  {...register("codudp")}
                  onChange={handleChangeCodudp}
                />
              </FormControlInput>
            </FormControl>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <RdtsDetailInfo>
              <p>
                USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br />
                IBM:<span>{sucursalSeleted}</span><br />
                FEC:<span>{fechaini}</span><br /><br />
                DIA:<span>"{nombreDia}"</span>
              </p>
            </RdtsDetailInfo>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <LogoImageWrapper>

            </LogoImageWrapper>
          </PropertyTypesContainerColum>

        </PropertyTypesContainerRow>
      </form>
      <br />
      <table>
        <thead>
          <tr>
            <th>Datos Trabajador</th>
            <th>Horas</th>
            <th>Cod</th>
            <th>Concepto</th>
            <th>Total</th>
            <th>UDP1</th>
            <th>Cant1</th>
            <th>UDP2</th>
            <th>Cant2</th>
            <th>UDP3</th>
            <th>Cant3</th>
            <th>UDP4</th>
            <th>Cant4</th>
            <th>Observación</th>
            <th>Ibm Laboro</th>
            <th>Reporto</th>
          </tr>
        </thead>
        <tbody>
          {rdts.map((item, key) => (
            <tr>
              <th>

                <PropertyCardWrapperTr>
                  <PropertyButtonWrapper>
                    {key}&nbsp;
                    <ButtonIconRemove icon={IoTrashOutline} onPress={() => showAlertYN(item._id)} />
                  </PropertyButtonWrapper>
                  <PropertyCardWrapper to={`/RdtDetailDistribution/${item._id}`}>
                    <PropertyInfoWrapper>
                      {item.strNombreTrabajador}
                      <SubTitle>
                        <span>{item.IntCodigo_EMPL} </span> - {item.strNombre_SUCU}
                      </SubTitle>
                    </PropertyInfoWrapper>
                  </PropertyCardWrapper>
                </PropertyCardWrapperTr>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.intHora_RDT}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.IntCodigo_CONC}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strConcepto}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{(item.intTotal_RDT).toFixed(2)}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strNombre_UDP}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.intCantidad_UDP}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strNombre_UDPdos}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.intCantidad_UDPdos}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strNombre_UDPtres}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.intCantidad_UDPtres}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strNombre_UDPcuatro}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.intCantidad_UDPcuatro}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>

                <PropertyDescriptionWrapper>
                  {item.IntCodigo_CONC === '118' ? item.strUdp.map((item, key) => (item.IntCodigo_UDP && item.intCantidad_UDP > 0 ? 'L' + item.IntCodigo_UDP + ' ' + item.strCinta_UDP + ' ' + item.strTipoCinta_UDP + ': ' + item.intCantidad_UDP + ' // ' : ''))
                    : item.IntCodigo_CONC === '119' ? item.strUdp.map((item, key) => (item.IntCodigo_UDP ? 'L' + item.IntCodigo_UDP + ' ' + item.strCinta_UDP + ': ' + item.intCantidadTotal_UDP + ' // ' : ''))
                      : item.IntCodigo_CONC === '451' ? item.strUdp.map((item, key) => (item.IntCodigo_UDP ? 'L' + item.IntCodigo_UDP + ' ' + item.strCinta_UDP + ': ' + item.intCantidadTotal_UDP + ' // ' : ''))
                        : item.strObservacion_RDT ? item.strObservacion_RDT : ''}
                </PropertyDescriptionWrapper>

              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.IbmLaboro === item.strCodigo_SUCU ? "" : item.IbmLaboro}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.email_USUA}
                </PropertyDescriptionWrapper>
              </th>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>T.REG: {rdts.length}</th>
            <th>{(sumhoras).toFixed(0)}</th>
            <th></th>
            <th></th>
            <th>{(sumtotal).toFixed(2)}</th>
            <th></th>
            <th>{(sumcant).toFixed(2)}</th>
            <th></th>
            <th>{(sumcantdos).toFixed(2)}</th>
            <th></th>
            <th>{(sumcanttres).toFixed(2)}</th>
            <th></th>
            <th>{(sumcantcuatro).toFixed(2)}</th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </Page>
  )
}
