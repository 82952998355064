import { useEffect, useState } from "react";
//import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import { useRdt } from "../../hooks/useRdt";
import { Page } from "../../components/Page";
import { PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { ButtonIcon } from "../../components/ButtonIcon";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { IoAccessibility, IoHomeSharp, IoBookmark, IoCloseCircleOutline } from "react-icons/io5";
import Alert from "../../components/Alert/Alert";
import { PropertyRdts } from "../AddRdts/components/PropertyRdts";
import { PropertyRdtstrabajador } from "../AddRdts/components/PropertyRdtstrabajador";
import { PropertyGraf } from "../AddRdts/components/PropertyGraf";
import { AuthorizationsRdts } from "../AddRdts/components/AuthorizationsRdts";
import { ButtonIconRibbon } from "../../components/ButtonIconRibbon";
import {
    AddRdtsWrapper_Global,
    AddRdtsWrapper_Global_DOS,
    PropertyTypesContainerColum,
    PropertyTypesContainerRow,
    FormControl,
    FormControlInput,
    FormControlInputSearch,
    FormControlInputSearchudp,
    FormControlInputudp,
    FormControlTitle,
    AddRdtsCombo,
    AddRdtsBusqueda,
    AddRdtsFill,
    AddRdtsFillnofound,
    AddRdtsFillnofoundFarm,
    FormControlInputTitel,
    FormControlHead,
    FormControlInputSearchcinta,
    FormControlSeccionOne,
    FormControlSeccionTwo,
    CerrarRdtsDistribution,
    FormControlRdtsDistribution,
    FormularioRdtsDistribution,
    FormControlHeadTittle,
} from "./styles";
import { PropertyRdtstrabajadorDistribution } from "./components/PropertyRdtstrabajadorDistribution";
import { PropertyRdtsDistribution } from "./components/PropertyRdtsDistribution";


const ALL_SUCURSALES = 0;
const HORA = 9.5;

let cinta = 'transparent', cintados = 'transparent', cintatres = 'transparent', cintacuatro = 'transparent', cintaPm = 0, cintaPs = 0;
let nameCinta = '', nameCintados = '', nameCintatres = '', nameCintacuatro = '', nameCintaPm = '', nameCintaPs = '';
let nameCintaCorto = '', nameCintadosCorto = '', nameCintatresCorto = '', nameCintacuatroCorto = '';

const colors = [
    { id: 1, cinta: 'brown', name: 'CAFE', nameCorto: 'CA' },
    { id: 2, cinta: 'black', name: 'NEGRA', nameCorto: 'NE' },
    { id: 3, cinta: 'orange', name: 'NARANJA', nameCorto: 'NA' },
    { id: 4, cinta: 'green', name: 'VERDE', nameCorto: 'VE' },
    { id: 5, cinta: 'yellow', name: 'AMARILLA', nameCorto: 'AM' },
    { id: 6, cinta: 'white', name: 'BLANCA', nameCorto: 'BL' },
    { id: 7, cinta: 'blue', name: 'AZUL', nameCorto: 'AZ' },
    { id: 8, cinta: 'gray', name: 'GRIS', nameCorto: 'GR' },
    { id: 9, cinta: 'purple', name: 'MORADA', nameCorto: 'MO' },
    { id: 10, cinta: 'red', name: 'ROJA', nameCorto: 'RO' },
];


export const AddRdtsDistribution = ({ setModalD, animarModald }) => {

    //Boton de cerrar------------//---------------Nuevo
    const ocultarRdtsDistribution = () => {
        //setAnimarModal(false)
        setModalD(false)

    }
    //Cierre Nuevo

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload, rdtEmploye, setRdtEmploye } = useRdt();
    /*
    const grabarButtonRef = useRef(null); // Referencia al botón

    const handleKeyDown = (e, tabIndex) => {
        if (e.key === 'Tab' && tabIndex === 15) {
            e.preventDefault(); // Evita el tab normal
            grabarButtonRef.current.focus(); // Mueve el foco al botón
        }
    };
    */
    //console.log({ rdtprovAddRDTDist: rdtprov });
    const navigate = useNavigate();

    const [sucursal, setSucursal] = useState([]);
    const [employe, setEmploye] = useState([]);
    const [concepto, setConcepto] = useState([]);
    const [udpuno, setUdpuno] = useState([]);
    const [udpdos, setUdpdos] = useState([]);
    const [udptres, setUdptres] = useState([]);
    const [udpcuatro, setUdpcuatro] = useState([]);
    const [sumcantidad, setSumcantidad] = useState(0);
    const [horas, setHoras] = useState(ALL_SUCURSALES);
    const [observacion, setObservacion] = useState('');
    const [date, setDate] = useState('');
    const [rdts, setRdts] = useState([]);
    const [rdtstrabajador, setRdtstrabajador] = useState([]);
    const [rdtsAuth, setRdtsAuth] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeesdials, setEmployeesdials] = useState([]);

    const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
    const [sucursalname, setSucursalname] = useState('');
    const [codigoSeleted, setCodigoSelected] = useState('');
    const [conceptoSeleted, setConceptoSelected] = useState('');

    const [udpunoselected, setUdpunoselected] = useState(0);
    const [udpdosselected, setUdpdosselected] = useState(0);
    const [udptresselected, setUdptresselected] = useState(0);
    const [udpcuatroselected, setUdpcuatroselected] = useState(0);

    const [cantudpunoselected, setCantudpunoselected] = useState(0);
    const [cantudpdosselected, setCantudpdosselected] = useState(0);
    const [cantudptresselected, setCantudptresselected] = useState(0);
    const [cantudpcuatroselected, setCantudpcuatroselected] = useState(0);

    //Se utilizan para registrar el cod 118 Embolse y especificar cinta Presente
    const [cantudpunoPsselected, setCantudpunoPsselected] = useState(0);
    const [cantudpdosPsselected, setCantudpdosPsselected] = useState(0);
    const [cantudptresPsselected, setCantudptresPsselected] = useState(0);
    const [cantudpcuatroPsselected, setCantudpcuatroPsselected] = useState(0);
    //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura
    const [cantudpunoPmselected, setCantudpunoPmselected] = useState(0);
    const [cantudpdosPmselected, setCantudpdosPmselected] = useState(0);
    const [cantudptresPmselected, setCantudptresPmselected] = useState(0);
    const [cantudpcuatroPmselected, setCantudpcuatroPmselected] = useState(0);
    //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura+Presente
    const [cantudpunoPPselected, setCantudpunoPPselected] = useState(0);
    const [cantudpdosPPselected, setCantudpdosPPselected] = useState(0);
    const [cantudptresPPselected, setCantudptresPPselected] = useState(0);
    const [cantudpcuatroPPselected, setCantudpcuatroPPselected] = useState(0);
    //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura+Presente
    const [sumcantPP, setsumCantPP] = useState(0);
    const [sumcantPm, setsumCantPm] = useState(0);
    const [sumcantPs, setsumCantPs] = useState(0);
    //cod para cinta en la labor 119 colocar guantelete
    const [cintaudpunoselected, setCintaudpunoselected] = useState(0);
    const [cintaudpdosselected, setCintaudpdosselected] = useState(0);
    const [cintaudptresselected, setCintaudptresselected] = useState(0);
    const [cintaudpcuatroselected, setCintaudpcuatroselected] = useState(0);
    //Buscar en calendario según fecha cintaPm y CintaPs que se esta trabajando
    const [calendar, setCalendar] = useState([]);

    const [multilabor, setMultilabor] = useState(0)

    const [alert, setAlert] = useState({})
    const [busqueda, setBusqueda] = useState('')
    const [dial, setDial] = useState({})

    const [modal, setModal] = useState(false);
    const [animarModal, setAnimarModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isValid
        }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
    });

    useEffect(() => {
        if (animarModald) {
            //console.log('6- Asigno al estado el codigo del trabajador y concepto: animarModald')
            setTimeout(() => {
                setDate((rdtprov.dateRdt).split('T')[0]);
            }, 1500)
            setTimeout(() => {
                setCodigoSelected(rdtprov.codTrab);
            }, 2000)
            setTimeout(() => {
                setConceptoSelected(rdtprov.codLab);
            }, 2500)
        }
    }, [animarModald]);

    //Token valido o no expirado
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                //console.log('1- Obtengo todas las sucursales: getSucursalesAll')
                await getSucursalesAll();
                //await getEmployeeDial(); //Mensajes o comunicados al inicio     

            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [auth])
    //Verificar estado empleado lleno y limpiar busqueda
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                cleanStatesConcepto();
                await getVerificarEmploye();
                await getRdtstrabajador();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [employe])
    //Recargar RDTs
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await reload ? getRdts() : console.log('');
                await reload ? getRdtstrabajador() : null();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [reload])
    //Si estoy editando
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await rdtprov.ibm ? setSucursalSelected(rdtprov.ibm) : null();
                await rdtprov.dateRdt ? setDate((rdtprov.dateRdt).split('T')[0]) : null();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [rdtprov])
    //Sucursals       
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                //console.log('2- Asigno a los estados los datos que pase: sucursal')
                setSucursalSelected(rdtprov.ibm);
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [sucursal])
    //Sucursal       
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await cleanStates();
                //console.log('3- Busco los Rdts y las autorizaciones de la finca: sucursalSeleted')
                await sucursalSeleted && date ? getRdts() : cleanStates();
                await sucursalSeleted && date ? getRdtsAuth() : cleanStates();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [sucursalSeleted])
    //date       
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                await cleanStates();
                //console.log('4- Busco a todos los trabajadores, marcaciones, Rdts, calendario : date', date)
                sucursalSeleted ? getNameSucursal() : null();
                await sucursalSeleted && date ? getEmployeesAll() : cleanStatesOnlyEmployees();
                await sucursalSeleted && date ? getEmployeesdialsAll() : cleanStatesOnlyEmployees();
                await sucursalSeleted && date ? getRdts() : cleanStates();
                await sucursalSeleted && date ? getRdtsAuth() : cleanStates();
                await sucursalSeleted && date ? getCalendar() : cleanStates();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [date])
    //Verificar Auth Finca
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                setDial([]);
                await sucursalSeleted && date ? verRdtsAuthFinca() : null();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [rdtsAuth])
    //Employees
    useEffect(() => {
        const getDataEmpl = async () => {
            try {
                const token = getToken();
                if (!token) return
                cleanStatesConcepto();
                //console.log('5- Busco el trabajador que pase:', codigoSeleted, sucursalSeleted, date)
                await codigoSeleted && sucursalSeleted && date ? handleEmployeCod() : cleanStatesEmployees();
            } catch (error) {
                console.log(error)
            }
        }
        getDataEmpl();
    }, [codigoSeleted])
    //Verificar employees Auth para reg de RDTs
    useEffect(() => {
        const getDataEmpl = async () => {
            try {
                const token = getToken();
                if (!token) return
                await codigoSeleted && sucursal && date ? verRdtsAuthEmploye() : console.log('');
            } catch (error) {
                console.log(error)
            }
        }
        getDataEmpl();
    }, [rdtstrabajador])
    //Conceptos       
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                conceptoSeleted && employe && date ? getConcepto() : cleanStatesConcepto();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [conceptoSeleted])

    //Udps uno     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpunoselected ? getUdpuno() : cleanStatesudpuno();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [udpunoselected])
    //Cantidad Prem y Pres Udps uno para cod 118: Embolse     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpunoselected ? setCantudpunoPPselected(Number(cantudpunoPmselected) + Number(cantudpunoPsselected)) : cleanStatesudpuno();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpunoPmselected, cantudpunoPsselected])
    //Udps dos     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpdosselected ? getUdpdos() : cleanStatesudpdos();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [udpdosselected])
    //Cantidad Prem y Pres Udps dos para cod 118: Embolse    
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpdosselected ? setCantudpdosPPselected(Number(cantudpdosPmselected) + Number(cantudpdosPsselected)) : cleanStatesudpdos();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpdosPmselected, cantudpdosPsselected])
    //Udps tres     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udptresselected ? getUdptres() : cleanStatesudptres();
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [udptresselected])
    //Cantidad Prem y Pres Udps tres para cod 118: Embolse    
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udptresselected ? setCantudptresPPselected(Number(cantudptresPmselected) + Number(cantudptresPsselected)) : cleanStatesudptres();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudptresPmselected, cantudptresPsselected])
    //Udps cuatro     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpcuatroselected ? getUdpcuatro() : cleanStatesudpcuatro();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [udpcuatroselected])
    //Cantidad Prem y Pres Udps cuatro para cod 118: Embolse    
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpcuatroselected ? setCantudpcuatroPPselected(Number(cantudpcuatroPmselected) + Number(cantudpcuatroPsselected)) : cleanStatesudpcuatro();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpcuatroPmselected, cantudpcuatroPsselected])
    //Suma Prem para cod 118: Embolse Prematuro
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpunoselected ? setsumCantPm(Number(cantudpunoPmselected) + Number(cantudpdosPmselected) + Number(cantudptresPmselected) + Number(cantudpcuatroPmselected)) : console.log('');
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpunoPmselected, cantudpdosPmselected,
        cantudptresPmselected, cantudpcuatroPmselected])
    //Suma Pres para cod 118: Embolse Presente   
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpunoselected ? setsumCantPs(Number(cantudpunoPsselected) + Number(cantudpdosPsselected) + Number(cantudptresPsselected) + Number(cantudpcuatroPsselected)) : console.log('');
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpunoPsselected, cantudpdosPsselected,
        cantudptresPsselected, cantudpcuatroPsselected])
    //Suma Prem+Pres para cod 118: Embolse Prematuro + Presente
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                concepto.intEXUDP === 1 && udpunoselected ? setsumCantPP(Number(sumcantPm) + Number(sumcantPs)) : console.log('');
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [sumcantPm, sumcantPs])
    //Sum Cantidad     
    useEffect(() => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                getSumcantidad();
            } catch (error) {
                console.log(error)
            }
        }
        getData();

    }, [cantudpunoselected, cantudpdosselected,
        cantudptresselected, cantudpcuatroselected])
    //Ventana Modal para Autorizaciones Employees
    const handleAutorizacionEmploye = () => {
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Valido si Fecha es mayor a la actual
        if (fecha > hoy) {
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Empleado        
        if (!employe[0]) {
            setAlert({
                msg: 'Se requiere codigo del trabajador para solicitar autorización de registro RDT',
                error: true
            })
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }

        const rdtData = {
            ibm: sucursalSeleted,
            dateRdt: date,
            nombreFinca: sucursalname,
            nombreDia: nombreDia
        };
        setRdtprov(rdtData)
        setRdtEmploye(employe)
        setModal(true)

        setTimeout(() => {
            setAnimarModal(true)
        }, 300);
    }
    //Ventana Modal para Autorizaciones Farms
    const handleAutorizacionFarm = () => {
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Valido si Fecha es mayor a la actual
        if (fecha > hoy) {
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        if ([sucursalname, date].includes('')) {
            setAlert({
                msg: 'Por favor seleccione la sucursal ó fecha del Rdt',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        const rdtData = {
            ibm: sucursalSeleted,
            dateRdt: date,
            nombreFinca: sucursalname,
            nombreDia: nombreDia
        };
        setEmploye([])
        setRdtEmploye([])
        setRdtprov(rdtData)
        setModal(true)

        setTimeout(() => {
            setAnimarModal(true)
        }, 300);
    }
    //Close session if token expired
    const hendleCloseSeccion = async () => {
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente',
            "Su token puede estar vencido",
            SW_ICON.INFO,
            () => { navigate('/Login') }
        );
    }
    //Sucursales
    const handleChange = event => {
        setRdtprov({});
        setSucursalSelected(event.target.value)
    }
    //Date       
    const handleChangedate = event => {
        setDate(event.target.value)
    }
    //Cod Labor
    const handleChangeCodLabor = event => {
        setConceptoSelected(event.target.value)
    }
    //Sucursals
    const getSucursalesAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',
                    params: makeSucursalesFilters()
                }
            );
            const { data } = response;
            setSucursal(data);
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = () => {
        const filters = {};
        return filters;
    }
    //Employees
    const handleEmployeCod = () => {
        const getData = async () => {
            try {
                const token = getToken();
                if (!token) return
                if ((auth.data.role !== 1) && (auth.data.role !== 2) && (auth.data.role !== 3)) {
                    showAlert(
                        'No Autorizado',
                        "Usuario no permitido para realizar esta operación",
                        SW_ICON.INFO
                    );
                    return
                }
                //console.log('employees', employees)
                await getEmployeesCod();
            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
        }
        getData();
    }
    const getEmployeesCod = async () => {
        try {     /*       
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersCod()                                     
                }
            );
            setMultilabor(0);             
            const {data} = response;              
            data ? setEmploye(data):cleanStatesConcepto();*/
            setMultilabor(0);
            //console.log('employees', employees)             
            const data = (employees.filter(employe => employe.IntCodigo === codigoSeleted))//Filtrar trabajador            
            await data ? setEmploye(data) : cleanStatesConcepto();
            //await data?console.log('employe tiene datos', data):console.log('employe No tiene datos', employe);

        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesFiltersCod = () => {
        const filters = {};
        if (codigoSeleted !== ALL_SUCURSALES) {
            filters['IntCodigo'] = codigoSeleted;
        }
        //console.log(filters);         
        return filters;
    }
    //EmployeesAllnombre     
    const getEmployeesAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',
                    params: makeEmployeesFiltersAll()
                }
            );
            const { data } = response;
            setEmployees(data);
            //console.log({ employees: data })
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesFiltersAll = () => {
        const filters = {};

        /*if((auth.data.role !== 1) && (auth.data.role !== 2)){
            filters['Ibm'] = auth.data.ibm;
        }*/
        //console.log(filters);         
        return filters;
    }
    const getVerificarEmploye = async () => {
        const resultado = employe ? setBusqueda('') : ''
        const resultadoDos = employe ? setCodigoSelected(employe[0].IntCodigo) : ''
        if (employeesFiltrados[0]) {
            const resultadoTres = employe ? FocusOnInputlabor() : ''
        }
        //const resultadoTres = employe?FocusOnInputlabor():''     
    }
    //EmployeesDials
    const getEmployeesdialsAll = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employeesdials',
                    params: makeEmployeesdialsFiltersAll()
                }
            );
            const { data } = response;
            setEmployeesdials(data);
            //console.log({ employeesdials: data })
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeEmployeesdialsFiltersAll = () => {
        const filters = {};
        filters['dtFecha'] = date;
        return filters;
    }
    //Employees Rdts Autorization without Dials
    const getRdtsAuth = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/rdtsauth',
                    params: makeRdtsAuthFiltersAll()
                }
            );
            const { data } = response;
            setRdtsAuth(data);
            //console.log({ rdtsAuth: rdtsAuth })
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makeRdtsAuthFiltersAll = () => {
        const filters = {};
        filters['Fecha_INI'] = date;
        filters['Fecha_FIN'] = date;
        filters['IbmLaboro'] = sucursalSeleted;
        filters['strEstado'] = 'Autorizado';
        //console.log(fechaini);
        return filters;
    }
    //Concepts
    const getConcepto = async () => {
        if (employe) {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/conceptossucursal',
                        params: makeConceptoFilters()
                    }
                );
                const { data } = response;
                //console.log({datos: data})   
                data ? setConcepto(data) : cleanStatesudps();
                cleanStatesudps();
            } catch (error) {
                console.log(error)
            }

        } else {
            cleanStatesConcepto();
        }
    }
    const makeConceptoFilters = () => {
        const filters = {};
        if (sucursalSeleted !== ALL_SUCURSALES && codigoSeleted !== ALL_SUCURSALES) {
            filters['Ibm'] = sucursalSeleted;
            filters['IntCodigo'] = conceptoSeleted;
        }
        //console.log(filters);         
        return filters;
    }
    //Udps uno
    const getUdpuno = async () => {
        //console.log({filtroudpuno: makeUdpunoFilters()})
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/udps',
                    params: makeUdpunoFilters()
                }
            );
            const { data } = response;
            data ? setUdpuno(data) : setUdpuno([]);

        } catch (error) {
            console.log(error)
        }
    }
    const makeUdpunoFilters = () => {
        const filters = {};
        if (sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES) {
            filters['Ibm'] = sucursalSeleted;
            filters['intClase'] = '2';
            filters['IntCodigo'] = udpunoselected;
        }
        return filters;
    }
    //Udps dos
    const getUdpdos = async () => {
        //console.log({filtroudpdos: makeUdpdosFilters()})
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/udps',
                    params: makeUdpdosFilters()
                }
            );
            const { data } = response;
            data ? setUdpdos(data) : setUdpdos([]);

        } catch (error) {
            console.log(error)
        }
    }
    const makeUdpdosFilters = () => {
        const filters = {};
        if (sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES) {
            filters['Ibm'] = sucursalSeleted;
            filters['intClase'] = '2';
            filters['IntCodigo'] = udpdosselected;
        }
        return filters;
    }
    //Udps tres
    const getUdptres = async () => {
        //console.log({filtroudptres: makeUdptresFilters()})
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/udps',
                    params: makeUdptresFilters()
                }
            );
            const { data } = response;
            data ? setUdptres(data) : setUdptres([]);

        } catch (error) {
            console.log(error)
        }
    }
    const makeUdptresFilters = () => {
        const filters = {};
        if (sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES) {
            filters['Ibm'] = sucursalSeleted;
            filters['intClase'] = '2';
            filters['IntCodigo'] = udptresselected;
        }
        return filters;
    }
    //Udps cuatro
    const getUdpcuatro = async () => {
        //console.log({filtroudpcuatro: makeUdpcuatroFilters()})
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/udps',
                    params: makeUdpcuatroFilters()
                }
            );
            const { data } = response;
            data ? setUdpcuatro(data) : setUdpcuatro([]);

        } catch (error) {
            console.log(error)
        }
    }
    const makeUdpcuatroFilters = () => {
        const filters = {};
        if (sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES) {
            filters['Ibm'] = sucursalSeleted;
            filters['intClase'] = '2';
            filters['IntCodigo'] = udpcuatroselected;
        }
        return filters;
    }
    //Cantidad
    const getSumcantidad = async () => {
        setSumcantidad(Number(cantudpunoselected) + Number(cantudpdosselected) +
            Number(cantudptresselected) + Number(cantudpcuatroselected));
    }
    //getRdts
    const getRdts = async () => {

        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts',
                    params: makePropertiesFilters()
                }
            );
            const { data } = response;
            setRdts(data);
            setReload(false);
            //console.log({ Rdts: data })

        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makePropertiesFilters = () => {
        const filters = {};
        filters['Fecha_INI'] = date;
        filters['Fecha_FIN'] = date;
        filters['IbmLaboro'] = sucursalSeleted;
        filters['employeesId'] = auth.data._id;
        filters['intLimit'] = 6;
        //console.log(filters);
        return filters;
    }
    //getRdtstrabajador y verifico si tiene marcación del dia para poder registrar el RDT
    const getRdtstrabajador = async () => {
        if (employe[0]) {
            try {
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts',
                        params: makePropertiesFilterstrabajador()
                    }
                );
                const { data } = response;
                data ? setRdtstrabajador(data) : console.log('esperando');
            } catch (error) {
                if (error.response.status === 401) {
                    hendleCloseSeccion();
                } else {
                    console.log(error)
                }
            }
        } else {
            setRdtstrabajador([])
        }

    }
    const makePropertiesFilterstrabajador = () => {
        const filters = {};
        filters['Fecha_INI'] = date;
        filters['Fecha_FIN'] = date;
        filters['IntCodigo_EMPL'] = employe[0] ? employe[0].IntCodigo : '';
        filters['strCodigo_SUCU'] = employe[0] ? employe[0].Ibm : '';
        //console.log({filtrotrab: filters});
        return filters;
    }
    //getCalendars para prematuro y presente
    const getCalendar = async () => {
        try {
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',
                    params: makePropertiesFiltersCalendar()
                }
            );
            const { data } = response;
            setCalendar(data);
            //console.log({ calendar: data });
            //console.log(calendar[0].cintaPm);    
            //console.log(calendar[0].cintaPs);                 
        } catch (error) {
            if (error.response.status === 401) {
                hendleCloseSeccion();
            } else {
                console.log(error)
            }
        }
    }
    const makePropertiesFiltersCalendar = () => {
        const filters = {};
        filters['Fecha'] = date;

        //filters['employeesId'] = auth.data._id;              
        //console.log(fechaini);
        return filters;
    }
    //Message for dial
    const getEmployeeDial = async () => {

        if (!dial[0]) {
            showAlert(
                '¡Información Importante!',
                //'Informamos que, a partir del 3 de abril de 2023, el sistema no permitirá registrar los trabajadores sin marcación, para realizar el registro deberá solicitar autorización con su respectiva justificación al área encargada (Agricultura, Auditoria ó Nomina). Informa al administrador y coordinadores de la finca, acerca de este mensaje. Muchas Gracias por su atención...',
                //'Informamos que, a partir del 03 de junio de 2024, el sistema solicitara para el codigo 118 embolse, la cantidad de bolsas prematuras y presentes por lote. Tambien se solicitara para el cod 119 Colocar Guantelete, cantidad y color de cinta por lote reportado. Informa al administrador y coordinadores de la finca, acerca de este mensaje. Muchas Gracias por su atención...',
                'Se les informa que a partir del 18 de febrero de 2025 el reporte de los RDT, se deberá hacer desde la opción registrar reparto y estará habilitado hasta la 1:00pm del siguiente día. Gracias por su gestión. Att: Gerencia.',
                SW_ICON.INFO,
                () => { FocusOnInput() }
            );
        }

        await setTimeout(() => {
            setAlert({})
        }, 7000)

    }
    //Verifico Auth Finca para realizar registros RDTs sin marcacion
    const verRdtsAuthFinca = async () => {
        await setDial(rdtsAuth.filter(farmAuth => farmAuth.IbmLaboro.includes(sucursalSeleted) && farmAuth.strRequestby.includes('FINCA')))//Filtrar Autorizacion Finca
        return
        //console.log({Resultado: dial})
    }
    //Verifico Auth Employe para realizar registros RDTs sin marcacion
    const verRdtsAuthEmploye = async () => {
        if (!employe[0]) {
            return
        }
        if (rdtsAuth.length > 0) {
            //console.log('Tiene Autorizaciones');
            if (rdtsAuth[0].strRequestby !== 'FINCA') {
                //console.log('Verificando autorizaciones trabajador');
                const eAuth = rdtsAuth.filter(employeAuth => employeAuth.strDocumento.includes(employe[0].strDocumento))//Filtrar autorizaciones trabajador                 
                if (eAuth.length > 0) {
                    //console.log('Se encontro autorizacion para el trabajador..');
                    await setDial(eAuth)//marcacion del trabajador
                    //console.log(eAuth)
                } else {
                    //console.log('No se encontro auth y se Verifica marcaciones del trabajador..');
                    await setDial(employeesdials.filter(employeDial => employeDial.strDocumento === employe[0].strDocumento))//Filtro marcacion del trabajador
                }
            } else {
                //console.log('Tiene Autorizacion toda la finca');
            }
        } else {
            //console.log('No Tiene Autorizaciones y consulto marcaciones...');
            await setDial(employeesdials.filter(employeDial => employeDial.strDocumento === employe[0].strDocumento))//Filtrar marcacion del trabajador
        }
        return
        //console.log({Resultado: dial})
    }
    //Clean states Y asigno nombre a sucursalname
    const getNameSucursal = () => {
        if (sucursalSeleted === '20128') {
            setSucursalname('EDEN')
        } else if (sucursalSeleted === '90032') {
            setSucursalname('TULE')
        } else if (sucursalSeleted === '20065') {
            setSucursalname('JUANCA')
        } else if (sucursalSeleted === '80108') {
            setSucursalname('LA MONICA')
        } else if (sucursalSeleted === '23270') {
            setSucursalname('TIKAL')
        } else if (sucursalSeleted === '20947') {
            setSucursalname('BUENOS AIRES')
        } else if (sucursalSeleted === '80132') {
            setSucursalname('AGUA VERDE')
        } else if (sucursalSeleted === '20180') {
            setSucursalname('TOSCANA')
        } else if (sucursalSeleted === '20175') {
            setSucursalname('ASTURIAS')
        } else if (sucursalSeleted === '20185') {
            setSucursalname('LA CEJA')
        } else if (sucursalSeleted === '20186') {
            setSucursalname('ARIZONA')
        } else if (sucursalSeleted === '20188') {
            setSucursalname('ALCATRAZ')
        } else if (sucursalSeleted === '20194') {
            setSucursalname('MANAURE')
        } else if (sucursalSeleted === '90008') {
            setSucursalname('LA AGUADA')
        } else {
            setSucursalname('')
        }

    }
    //Clean states Y asigno nombre a sucursalname
    const cleanStates = () => {
        //setCodigoSelected('');
        //setConceptoSelected('');
        setUdpunoselected(0);
        setUdpdosselected(0);
        setUdptresselected(0);
        setUdpcuatroselected(0);
        setHoras(0);
        setSumcantidad(0);
        setObservacion('');

        setEmploye([]);
        setConcepto([]);
        setRdtstrabajador([]);
        setRdtsAuth([]);
        setEmploye([]);
        setUdpuno([]);
        setUdpdos([]);
        setUdptres([]);
        setUdpcuatro([]);

        if (sucursalSeleted === '20128') {
            setSucursalname('EDEN')
        } else if (sucursalSeleted === '90032') {
            setSucursalname('TULE')
        } else if (sucursalSeleted === '20065') {
            setSucursalname('JUANCA')
        } else if (sucursalSeleted === '80108') {
            setSucursalname('LA MONICA')
        } else if (sucursalSeleted === '23270') {
            setSucursalname('TIKAL')
        } else if (sucursalSeleted === '20947') {
            setSucursalname('BUENOS AIRES')
        } else if (sucursalSeleted === '80132') {
            setSucursalname('AGUA VERDE')
        } else if (sucursalSeleted === '20180') {
            setSucursalname('TOSCANA')
        } else if (sucursalSeleted === '20175') {
            setSucursalname('ASTURIAS')
        } else if (sucursalSeleted === '20185') {
            setSucursalname('LA CEJA')
        } else if (sucursalSeleted === '20186') {
            setSucursalname('ARIZONA')
        } else if (sucursalSeleted === '20188') {
            setSucursalname('ALCATRAZ')
        } else if (sucursalSeleted === '20194') {
            setSucursalname('MANAURE')
        } else if (sucursalSeleted === '90008') {
            setSucursalname('LA AGUADA')
        } else {
            setSucursalname('')
        }
    }
    //Clean states employees
    const cleanStatesEmployees = () => {
        setEmploye([]);
        setConceptoSelected('');
        setConcepto([]);
        setRdtstrabajador([]);
        setHoras(0);
        setSumcantidad(0);
        setObservacion('');
        setUdpunoselected(0);
        setUdpdosselected(0);
        setUdptresselected(0);
        setUdpcuatroselected(0);

    }
    //Clean only states employees
    const cleanStatesOnlyEmployees = () => {
        setEmployees([]);
    }
    //Clean states concepto y udps
    const cleanStatesConcepto = () => {
        setConcepto([]);
        setHoras(0);
        if (multilabor != 1) {
            //console.log('estoy en clean conceptos')
            setUdpunoselected(0);
            setUdpuno([]);
            setUdpdosselected(0);
            setUdpdos([]);
            setUdptresselected(0);
            setUdptres([]);
            setUdpcuatroselected(0);
            setUdpcuatro([]);

            setCantudpunoselected(0);
            setCantudpdosselected(0);
            setCantudptresselected(0);
            setCantudpcuatroselected(0);

            setCantudpunoPmselected(0);
            setCantudpdosPmselected(0);
            setCantudptresPmselected(0);
            setCantudpcuatroPmselected(0);

            setCantudpunoPsselected(0);
            setCantudpdosPsselected(0);
            setCantudptresPsselected(0);
            setCantudpcuatroPsselected(0);

            setCantudpunoPPselected(0);
            setCantudpdosPPselected(0);
            setCantudptresPPselected(0);
            setCantudpcuatroPPselected(0);

            setsumCantPP(0);
            setsumCantPs(0);
            setsumCantPm(0);

            setSumcantidad(0);
            setObservacion('');

            setCintaudpunoselected(0);
            setCintaudpdosselected(0);
            setCintaudptresselected(0);
            setCintaudpcuatroselected(0);

            cinta = 'transparent';
            nameCinta = '';
            nameCintaCorto = '';
            cintados = 'transparent';
            nameCintados = '';
            nameCintadosCorto = '';
            cintatres = 'transparent';
            nameCintatres = '';
            nameCintatresCorto = '';
            cintacuatro = 'transparent';
            nameCintacuatro = '';
            nameCintacuatroCorto = '';
        }
    }
    //Clean states uds
    const cleanStatesudps = () => {
        if (multilabor != 1) {
            setUdpunoselected(0);
            setUdpuno([]);
            setUdpdosselected(0);
            setUdpdos([]);
            setUdptresselected(0);
            setUdptres([]);
            setUdpcuatroselected(0);
            setUdpcuatro([]);

            setCantudpunoselected(0);
            setCantudpdosselected(0);
            setCantudptresselected(0);
            setCantudpcuatroselected(0);

            setCantudpunoPmselected(0);
            setCantudpdosPmselected(0);
            setCantudptresPmselected(0);
            setCantudpcuatroPmselected(0);

            setCantudpunoPsselected(0);
            setCantudpdosPsselected(0);
            setCantudptresPsselected(0);
            setCantudpcuatroPsselected(0);

            setCantudpunoPPselected(0);
            setCantudpdosPPselected(0);
            setCantudptresPPselected(0);
            setCantudpcuatroPPselected(0);

            setsumCantPP(0);
            setsumCantPs(0);
            setsumCantPm(0);

            setSumcantidad(0);

            setCintaudpunoselected(0);
            setCintaudpdosselected(0);
            setCintaudptresselected(0);
            setCintaudpcuatroselected(0);

            cinta = 'transparent';
            nameCinta = '';
            nameCintaCorto = '';
            cintados = 'transparent';
            nameCintados = '';
            nameCintadosCorto = '';
            cintatres = 'transparent';
            nameCintatres = '';
            nameCintatresCorto = '';
            cintacuatro = 'transparent';
            nameCintacuatro = '';
            nameCintacuatroCorto = '';
        }



    }
    //Clean states uds uno
    const cleanStatesudpuno = () => {
        if (multilabor != 1) {
            setCantudpunoselected(0);
            setUdpuno([]);
            setCintaudpunoselected(0);
            cinta = 'transparent';
            nameCinta = '';
            nameCintaCorto = '';
            if (concepto.IntCodigo == 118) {
                setCantudpunoPmselected(0);
                setCantudpunoPsselected(0);
                setCantudpunoPPselected(0);
            }
        }
    }
    //Clean states uds dos
    const cleanStatesudpdos = () => {
        if (multilabor != 1) {
            setCantudpdosselected(0);
            setUdpdos([]);
            setCintaudpdosselected(0);
            cintados = 'transparent';
            nameCintados = '';
            nameCintadosCorto = '';
            if (concepto.IntCodigo == 118) {
                setCantudpdosPmselected(0);
                setCantudpdosPsselected(0);
                setCantudpdosPPselected(0);
            }
        }
    }
    //Clean states uds tres
    const cleanStatesudptres = () => {
        if (multilabor != 1) {
            setCantudptresselected(0);
            setUdptres([]);

            setCintaudptresselected(0);
            cintatres = 'transparent';
            nameCintatres = '';
            nameCintatresCorto = '';
            if (concepto.IntCodigo == 118) {
                setCantudptresPmselected(0);
                setCantudptresPsselected(0);
                setCantudptresPPselected(0);
            }
        }

    }
    //Clean states udp cuatro
    const cleanStatesudpcuatro = () => {
        if (multilabor != 1) {
            setCantudpcuatroselected(0);
            setUdpcuatro([]);
            setCintaudpcuatroselected(0);
            cintacuatro = 'transparent';
            nameCintacuatro = '';
            nameCintacuatroCorto = '';
            if (concepto.IntCodigo == 118) {
                setCantudpcuatroPmselected(0);
                setCantudpcuatroPsselected(0);
                setCantudpcuatroPPselected(0)
            }
        }
    }
    //Grabar
    const onSubmitsave = async (data) => {
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                                  
        if (numeroDia >= 5) {
            //rango para el viernes de 4 dias.
            if (numeroDia == 5) {
                if (rango > 363599999) {
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            } else {
                //rango para el sabado de 3 dias.                     
                if (rango > 276999999) {
                    //if(rango > 363599999){ //Festivo dia lunes
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
            }
        } else {
            //Rango de 2 dias para registrar de lunes a jueves
            if (rango > 189999999) {
                //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Valido si Fecha es mayor a la actual
        if (fecha > hoy) {
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //HORA--Valido Fecha para poder realizar registros antes de las 13pm
        const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0 indexados
            const year = date.getFullYear();
            /*
            // Formatear hora            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            console.log({hours: hours})
            */

            return `${year}-${month}-${day}`;
            //return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        };
        let currentDate = formatDate(Date.now());
        //console.log({ currentDate: currentDate })
        //console.log({ date: date })
        if (date < currentDate) {
            //Valido hora
            const formatDatehour = (timestamp) => {
                const date = new Date(timestamp);
                //Formatear hora            
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                //console.log({hours: hours});         
                return `${hours}`;
            };
            let currentDateHour = formatDatehour(Date.now());

            //Hora para grabar debe ser menor a la 13 de la tarde.
            if (currentDateHour > 13) {
                showAlert(
                    'No Permitido',
                    "Señor usuario recuerde que solo se permite registrar RDTs antes de la 1pm",
                    SW_ICON.ERROR
                );
                return
            }
        }
        //console.log(data)              
        let sumaH = 0;
        //Valido que campo tenga cod Empleado
        if (!employe[0]) {
            setAlert({
                msg: 'Se requiere codigo del trabajador',
                error: true
            })
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Valido que empleado a grabar si sea el mismo que digite 
        if (codigoSeleted != employe[0].IntCodigo) {
            setAlert({
                msg: 'El codigo del trabajador digitado es diferente al consultado " Verifique si existe"',
                error: true
            })
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        /*
        //Valido que trabajador este incluido dentro del reparto o tenga autorización.
        const emplReparto = Object.values(rdtprov.boardingStaff).some(group => 
            group.some(persona => persona.IntCodigo === employe[0].IntCodigo)
        );
        if(!emplReparto){
            if (rdtsAuth.length > 0) {
                //console.log('Tiene Autorizaciones');
                if (rdtsAuth[0].strRequestby !== 'FINCA') {
                    //console.log('Verificando autorizaciones trabajador');
                    const eAuth = rdtsAuth.filter(employeAuth => employeAuth.strDocumento.includes(employe[0].strDocumento))//Filtrar autorizaciones trabajador                 
                    if (eAuth.length > 0) {
                        //console.log('Se encontro autorizacion para el trabajador..');
                        //await setDial(eAuth)//marcacion del trabajador
                        //console.log(eAuth)
                    } else {
                        //console.log('No se encontro auth del trabajador..');
                        setAlert({
                            msg: 'El trabajador no esta incluido en el reparto de la finca y no tiene autorización, para reportar RDT del día',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                } else {
                    //console.log('Tiene Autorizacion toda la finca');
                }
            } else {
                //console.log('No Tiene Autorizaciones y consulto marcaciones...');
                setAlert({
                    msg: 'El trabajador no esta incluido en el reparto de la finca y no tiene autorización, para reportar RDT del día',
                    error: true
                })
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
             
        }*/
        //console.log({emplReparto: emplReparto})
        //Valido que trabajdor tenga marcación en el dia
        if (!dial[0]) {
            setAlert({
                msg: 'Se requiere registro de marcación del trabajador para reportar RDT del día',
                error: true
            })
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Sumar horas reportadas al trabajador
        if (rdtstrabajador[0]) {
            const lista = rdtstrabajador.map(item => ({
                sumaHoras: Math.floor(item.intHora_RDT)
            }));
            //console.log(lista);               
            sumaH = lista.reduce((prev, next) => prev + next.sumaHoras, 0);

            //console.log({horas: sumaH})
        }
        if (!concepto.IntCodigo) {
            setAlert({
                msg: 'Se requiere codigo de la labor realizada',
                error: true
            })
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Valido que concepto a grabar si sea el mismo que digite 
        if (conceptoSeleted != concepto.IntCodigo) {
            setAlert({
                msg: 'El codigo de la labor digitada es diferente a la consultada " Verifique si existe "',
                error: true
            })
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Valido si concepto exige UDP
        if (concepto.intEXUDP === 1) {
            if (concepto.strUMedida === 'Hectarea') {
                //udp1                        
                if (!udpuno[0]) {
                    setAlert({
                        msg: 'Necesita digitar la UDP1',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                //Valido que UDP1 a grabar si sea el mismo que digite 
                if (udpunoselected != udpuno[0].IntCodigo) {
                    setAlert({
                        msg: '" UDP1 digitada" es diferente a la consultada " Verifique UDP1 "',
                        error: true
                    })
                    //console.log({mensajeincludes: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
                if (Number(cantudpunoselected) <= 0) {
                    setAlert({
                        msg: 'Necesita digitar una cantidad para la "UDP1"',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                //Si estoy registrando Embolse labor 118: garantizo que se grabe por lo menos el embolse prematuro--------Embolse--------------------------------------->
                if (concepto.IntCodigo === '118' && Number(cantudpunoPmselected) <= 0) {
                    setAlert({
                        msg: 'Necesita digitar una cantidad de embolse prematuro para la "UDP1"',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                //Si estoy registrando Manejo Cobertura, Manejo Cobertura Contacto, Fumigacion Contacto, Fumigacion Herbicida Sistemico cant permitidad hasta su Area Bruta                
                if (concepto.IntCodigo === '143' || concepto.IntCodigo === '142' || concepto.IntCodigo === '137' || concepto.IntCodigo === '155') {
                    if (Number(cantudpunoselected) > udpuno[0].intABruta) {
                        setAlert({
                            msg: 'Cantidad para "UDP1" no puede ser mayor a su "Area Bruta"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                } else {
                    if (Number(cantudpunoselected) > udpuno[0].intANeta) {
                        setAlert({
                            msg: 'Cantidad para "UDP1" no puede ser mayor a su "Area Neta"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                }
                //udp2                      
                if (udpdos[0]) {
                    if (Number(cantudpdosselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP2"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    //Si estoy registrando Embolse labor 118: garantizo que se grabe por lo menos el embolse prematuro--------Embolse--------------------------------------->
                    if (concepto.IntCodigo === '118' && Number(cantudpdosPmselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad de embolse prematuro para la "UDP2"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP2 a grabar si sea el mismo que digite 
                    if (udpdosselected != udpdos[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP2 digitada" es diferente a la consultada " Verifique UDP2 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    if (concepto.IntCodigo === '143' || concepto.IntCodigo === '142' || concepto.IntCodigo === '137' || concepto.IntCodigo === '155') {
                        if (Number(cantudpdosselected) > udpdos[0].intABruta) {
                            setAlert({
                                msg: 'Cantidad para "UDP2" no puede ser mayor a su "Area Bruta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    } else {
                        if (Number(cantudpdosselected) > udpdos[0].intANeta) {
                            setAlert({
                                msg: 'Cantidad para "UDP2" no puede ser mayor a su "Area Neta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    }
                }
                //udp3
                if (udptres[0]) {
                    if (Number(cantudptresselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP3"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Si estoy registrando Embolse labor 118: garantizo que se grabe por lo menos el embolse prematuro--------Embolse--------------------------------------->
                    if (concepto.IntCodigo === '118' && Number(cantudptresPmselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad de embolse prematuro para la "UDP3"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP3 a grabar si sea el mismo que digite 
                    if (udptresselected != udptres[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP3 digitada" es diferente a la consultada " Verifique UDP3 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    if (concepto.IntCodigo === '143' || concepto.IntCodigo === '142' || concepto.IntCodigo === '137' || concepto.IntCodigo === '155') {
                        if (Number(cantudptresselected) > udptres[0].intABruta) {
                            setAlert({
                                msg: 'Cantidad para "UDP3" no puede ser mayor a su "Area Bruta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    } else {
                        if (Number(cantudptresselected) > udptres[0].intANeta) {
                            setAlert({
                                msg: 'Cantidad para "UDP3" no puede ser mayor a su "Area Neta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    }
                }
                if (udpcuatro[0]) {
                    if (Number(cantudpcuatroselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP4"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Si estoy registrando Embolse labor 118: garantizo que se grabe por lo menos el embolse prematuro--------Embolse--------------------------------------->
                    if (concepto.IntCodigo === '118' && Number(cantudpcuatroPmselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad de embolse prematuro para la "UDP4"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP4 a grabar si sea el mismo que digite 
                    if (udpcuatroselected != udpcuatro[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP4 digitada" es diferente a la consultada " Verifique UDP4 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    if (concepto.IntCodigo === '143' || concepto.IntCodigo === '142' || concepto.IntCodigo === '137' || concepto.IntCodigo === '155') {
                        if (Number(cantudpcuatroselected) > udpcuatro[0].intABruta) {
                            setAlert({
                                msg: 'Cantidad para "UDP4" no puede ser mayor a su "Area Bruta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    } else {
                        if (Number(cantudpcuatroselected) > udpcuatro[0].intANeta) {
                            setAlert({
                                msg: 'Cantidad para "UDP4" no puede ser mayor a su "Area Neta"',
                                error: true
                            })
                            setTimeout(() => {
                                setAlert({})
                            }, 10000)
                            return
                        }
                    }
                }
            } else {
                //Debe tener por lo menos la UDP1 digitada
                if (!udpuno[0]) {
                    setAlert({
                        msg: 'Necesita digitar la UDP1',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                //Valido que UDP1 a grabar si sea el mismo que digite 
                if (udpunoselected != udpuno[0].IntCodigo) {
                    setAlert({
                        msg: '" UDP1 digitada" es diferente a la consultada " Verifique UDP1 "',
                        error: true
                    })
                    //console.log({mensajeincludes: alert.msg}); 
                    setTimeout(() => {
                        setAlert({})
                    }, 7000)
                    return
                }
                if (Number(cantudpunoselected) <= 0) {
                    setAlert({
                        msg: 'Necesita digitar una cantidad para la "UDP1"',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                //Valido para colocar guantelete tenga digitado el color de cinta de la cant a reportar-----------Guantelete------------------->
                if (concepto.IntCodigo == 119 && nameCinta == '' || concepto.IntCodigo == 451 && nameCinta == '') {
                    setAlert({
                        msg: 'Necesita digitar una cinta para la "UDP1"',
                        error: true
                    })
                    setTimeout(() => {
                        setAlert({})
                    }, 10000)
                    return
                }
                if (udpdos[0]) {
                    if (Number(cantudpdosselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP2"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP2 a grabar si sea el mismo que digite 
                    if (udpdosselected != udpdos[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP2 digitada" es diferente a la consultada " Verifique UDP2 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    //Valido para colocar guantelete tenga digitado el color de cinta de la cant a reportar-----------Guantelete------------------->
                    if (concepto.IntCodigo == 119 && nameCintados == '' || concepto.IntCodigo == 451 && nameCinta == '') {
                        setAlert({
                            msg: 'Necesita digitar una cinta para la "UDP2"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                }
                if (udptres[0]) {
                    if (Number(cantudptresselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP3"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP3 a grabar si sea el mismo que digite 
                    if (udptresselected != udptres[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP3 digitada" es diferente a la consultada " Verifique UDP3 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    //Valido para colocar guantelete tenga digitado el color de cinta de la cant a reportar-----------Guantelete------------------->
                    if (concepto.IntCodigo == 119 && nameCintatres == '' || concepto.IntCodigo == 451 && nameCinta == '') {
                        setAlert({
                            msg: 'Necesita digitar una cinta para la "UDP3"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                }
                if (udpcuatro[0]) {
                    if (Number(cantudpcuatroselected) <= 0) {
                        setAlert({
                            msg: 'Necesita digitar una cantidad para la "UDP4"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                    //Valido que UDP4 a grabar si sea el mismo que digite 
                    if (udpcuatroselected != udpcuatro[0].IntCodigo) {
                        setAlert({
                            msg: '" UDP4 digitada" es diferente a la consultada " Verifique UDP4 "',
                            error: true
                        })
                        //console.log({mensajeincludes: alert.msg}); 
                        setTimeout(() => {
                            setAlert({})
                        }, 7000)
                        return
                    }
                    //Valido para colocar guantelete tenga digitado el color de cinta de la cant a reportar-----------Guantelete------------------->
                    if (concepto.IntCodigo == 119 && nameCintacuatro == '' || concepto.IntCodigo == 451 && nameCinta == '') {
                        setAlert({
                            msg: 'Necesita digitar una cinta para la "UDP4"',
                            error: true
                        })
                        setTimeout(() => {
                            setAlert({})
                        }, 10000)
                        return
                    }
                }
            }

        } else {
            //console.log('Estoy dentro de EXUDP que no tiene nada')
            if (multilabor === 1) {
                setAlert({
                    msg: 'No se puede grabar esta labor con UDPs, desmarque la opcion de MultiLabor para continuar',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                setCodigoSelected('')
                return

            }

        }
        //Horas
        if (dias[numeroDia] === 'VIERNES') {
            const acumHoras = Number(sumaH) + Number(horas);
            //console.log({acumulado: acumHoras})
            if (Number(acumHoras) > Number(8)) {
                setAlert({
                    msg: 'Horas no pueden superar el max de "8 horas" para el trabajador en el día',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        if (dias[numeroDia] === 'SABADO' || dias[numeroDia] === 'DOMINGO') {
            if (Number(horas) > Number(0)) {
                setAlert({
                    msg: 'Hora debe ser "0 horas" para el trabajador los dias sabado ó domingo',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(() => {
                    setAlert({})
                }, 7000)
                return
            }
        }
        //Lunes-Jueves       
        const acumHoras = Number(sumaH) + Number(horas);
        if (Number(acumHoras) > Number(HORA)) {
            setAlert({
                msg: 'Horas no pueden superar el max de "9.5 horas" para el trabajador en el día',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        if (Number(sumcantidad) <= 0 || sumcantidad === '') {
            setAlert({
                msg: 'Cantidad total no puede ser igual a cero "0"',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(() => {
                setAlert({})
            }, 7000)
            return
        }
        //Finca Laboro
        let FincaLaboro = '';
        if (sucursalSeleted === '20175') {
            FincaLaboro = 'FINCA ASTURIAS'
        } else if (sucursalSeleted === '20180') {
            FincaLaboro = 'FINCA TOSCANA'
        } else if (sucursalSeleted === '20186') {
            FincaLaboro = 'FINCA ARIZONA'
        } else if (sucursalSeleted === '80132') {
            FincaLaboro = 'FINCA AGUA VERDE'
        } else if (sucursalSeleted === '20188') {
            FincaLaboro = 'FINCA ALCATRAZ'
        } else if (sucursalSeleted === '20947') {
            FincaLaboro = 'FINCA BUENOS AIRES'
        } else if (sucursalSeleted === '20128') {
            FincaLaboro = 'FINCA EDEN'
        } else if (sucursalSeleted === '20065') {
            FincaLaboro = 'FINCA JUANCA'
        } else if (sucursalSeleted === '23270') {
            FincaLaboro = 'FINCA TIKAL'
        } else if (sucursalSeleted === '80108') {
            FincaLaboro = 'FINCA LA MONICA'
        } else if (sucursalSeleted === '20185') {
            FincaLaboro = 'FINCA LA CEJA'
        } else if (sucursalSeleted === '20194') {
            FincaLaboro = 'FINCA MANAURE'
        }
        //Armo mi data para enviar a grabar
        setAlert({});
        try {
            const fecha = Date.now();
            const dataRdt = {};
            dataRdt['IntCodigo_EMPL'] = employe[0].IntCodigo;
            dataRdt['strDocumento'] = employe[0].strDocumento;
            dataRdt['strNombreTrabajador'] = employe[0].strNombreTrabajador;
            dataRdt['strCodigo_SUCU'] = employe[0].Ibm;
            dataRdt['strNombre_SUCU'] = employe[0].strCodigoCentro;
            dataRdt['strNombre_EMPR'] = employe[0].Contratista;
            dataRdt['IntCodigo_CONC'] = concepto.IntCodigo;
            dataRdt['strConcepto'] = concepto.strConcepto;
            dataRdt['intHora_RDT'] = horas;
            dataRdt['intTotal_RDT'] = data.total;
            dataRdt['dtmFecha_RDT'] = date;
            dataRdt['IbmLaboro'] = sucursalSeleted;
            dataRdt['strFincaLaboro'] = FincaLaboro;
            dataRdt['strObservacion_RDT'] = rdtprov.repart + ' ' + observacion;
            dataRdt['email_USUA'] = auth.data.email;
            dataRdt['dtmFechaCrea'] = fecha;
            dataRdt['dtmFechaModifica'] = fecha;
            dataRdt['employeesId'] = auth.data._id;
            dataRdt['intCiclo'] = concepto.intCiclo;

            dataRdt['IntCodigo_UDP'] = udpuno[0] ? udpuno[0].IntCodigo : '';
            dataRdt['strNombre_UDP'] = udpuno[0] ? udpuno[0].strDescripcion : '';
            dataRdt['intCantidad_UDP'] = udpuno[0] ? cantudpunoselected : '';

            dataRdt['IntCodigo_UDPdos'] = udpdos[0] ? udpdos[0].IntCodigo : '';
            dataRdt['strNombre_UDPdos'] = udpdos[0] ? udpdos[0].strDescripcion : '';
            dataRdt['intCantidad_UDPdos'] = udpdos[0] ? cantudpdosselected : '';

            dataRdt['IntCodigo_UDPtres'] = udptres[0] ? udptres[0].IntCodigo : '';
            dataRdt['strNombre_UDPtres'] = udptres[0] ? udptres[0].strDescripcion : '';
            dataRdt['intCantidad_UDPtres'] = udptres[0] ? cantudptresselected : '';

            dataRdt['IntCodigo_UDPcuatro'] = udpcuatro[0] ? udpcuatro[0].IntCodigo : '';
            dataRdt['strNombre_UDPcuatro'] = udpcuatro[0] ? udpcuatro[0].strDescripcion : '';
            dataRdt['intCantidad_UDPcuatro'] = udpcuatro[0] ? cantudpcuatroselected : '';

            dataRdt['intTotal_RDT'] = sumcantidad;

            //Embolse            
            if (concepto.IntCodigo == 118) {
                dataRdt['strUdp'] = [
                    { "IntCodigo_UDP": udpuno[0] ? udpuno[0].IntCodigo : '', "strNombre_UDP": udpuno[0] ? udpuno[0].strDescripcion : '', "strCinta_UDP": udpuno[0] ? nameCintaPm : '', "intCantidad_UDP": udpuno[0] ? cantudpunoPmselected : '', "strTipoCinta_UDP": udpuno[0] ? 'PM' : '', "intCodTipo_UDP": udpuno[0] ? 1 : '', "intCantidadTotal_UDP": udpuno[0] ? cantudpunoselected : '' },
                    { "IntCodigo_UDP": udpuno[0] ? udpuno[0].IntCodigo : '', "strNombre_UDP": udpuno[0] ? udpuno[0].strDescripcion : '', "strCinta_UDP": udpuno[0] ? nameCintaPs : '', "intCantidad_UDP": udpuno[0] ? cantudpunoPsselected : '', "strTipoCinta_UDP": udpuno[0] ? 'PS' : '', "intCodTipo_UDP": udpuno[0] ? 2 : '', "intCantidadTotal_UDP": udpuno[0] ? 0 : '' },
                    { "IntCodigo_UDP": udpdos[0] ? udpdos[0].IntCodigo : '', "strNombre_UDP": udpdos[0] ? udpdos[0].strDescripcion : '', "strCinta_UDP": udpdos[0] ? nameCintaPm : '', "intCantidad_UDP": udpdos[0] ? cantudpdosPmselected : '', "strTipoCinta_UDP": udpdos[0] ? 'PM' : '', "intCodTipo_UDP": udpdos[0] ? 1 : '', "intCantidadTotal_UDP": udpdos[0] ? cantudpdosselected : '' },
                    { "IntCodigo_UDP": udpdos[0] ? udpdos[0].IntCodigo : '', "strNombre_UDP": udpdos[0] ? udpdos[0].strDescripcion : '', "strCinta_UDP": udpdos[0] ? nameCintaPs : '', "intCantidad_UDP": udpdos[0] ? cantudpdosPsselected : '', "strTipoCinta_UDP": udpdos[0] ? 'PS' : '', "intCodTipo_UDP": udpdos[0] ? 2 : '', "intCantidadTotal_UDP": udpdos[0] ? 0 : '' },
                    { "IntCodigo_UDP": udptres[0] ? udptres[0].IntCodigo : '', "strNombre_UDP": udptres[0] ? udptres[0].strDescripcion : '', "strCinta_UDP": udptres[0] ? nameCintaPm : '', "intCantidad_UDP": udptres[0] ? cantudptresPmselected : '', "strTipoCinta_UDP": udptres[0] ? 'PM' : '', "intCodTipo_UDP": udptres[0] ? 1 : '', "intCantidadTotal_UDP": udptres[0] ? cantudptresselected : '' },
                    { "IntCodigo_UDP": udptres[0] ? udptres[0].IntCodigo : '', "strNombre_UDP": udptres[0] ? udptres[0].strDescripcion : '', "strCinta_UDP": udptres[0] ? nameCintaPs : '', "intCantidad_UDP": udptres[0] ? cantudptresPsselected : '', "strTipoCinta_UDP": udptres[0] ? 'PS' : '', "intCodTipo_UDP": udptres[0] ? 2 : '', "intCantidadTotal_UDP": udptres[0] ? 0 : '' },
                    { "IntCodigo_UDP": udpcuatro[0] ? udpcuatro[0].IntCodigo : '', "strNombre_UDP": udpcuatro[0] ? udpcuatro[0].strDescripcion : '', "strCinta_UDP": udpcuatro[0] ? nameCintaPm : '', "intCantidad_UDP": udpcuatro[0] ? cantudpcuatroPmselected : '', "strTipoCinta_UDP": udpcuatro[0] ? 'PM' : '', "intCodTipo_UDP": udpcuatro[0] ? 1 : '', "intCantidadTotal_UDP": udpcuatro[0] ? cantudpcuatroselected : '' },
                    { "IntCodigo_UDP": udpcuatro[0] ? udpcuatro[0].IntCodigo : '', "strNombre_UDP": udpcuatro[0] ? udpcuatro[0].strDescripcion : '', "strCinta_UDP": udpcuatro[0] ? nameCintaPs : '', "intCantidad_UDP": udpcuatro[0] ? cantudpcuatroPsselected : '', "strTipoCinta_UDP": udpcuatro[0] ? 'PS' : '', "intCodTipo_UDP": udpcuatro[0] ? 2 : '', "intCantidadTotal_UDP": udpcuatro[0] ? 0 : '' }
                ]
            }
            //Colocar Guantelete
            if (concepto.IntCodigo == 119) {
                dataRdt['strUdp'] = [
                    { "IntCodigo_UDP": udpuno[0] ? udpuno[0].IntCodigo : '', "strNombre_UDP": udpuno[0] ? udpuno[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpunoselected : '', "strCinta_UDP": udpuno[0] ? nameCinta : '', "intCantidadTotal_UDP": udpuno[0] ? cantudpunoselected : '' },
                    { "IntCodigo_UDP": udpdos[0] ? udpdos[0].IntCodigo : '', "strNombre_UDP": udpdos[0] ? udpdos[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpdosselected : '', "strCinta_UDP": udpdos[0] ? nameCintados : '', "intCantidadTotal_UDP": udpdos[0] ? cantudpdosselected : '' },
                    { "IntCodigo_UDP": udptres[0] ? udptres[0].IntCodigo : '', "strNombre_UDP": udptres[0] ? udptres[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudptresselected : '', "strCinta_UDP": udptres[0] ? nameCintatres : '', "intCantidadTotal_UDP": udptres[0] ? cantudptresselected : '' },
                    { "IntCodigo_UDP": udpcuatro[0] ? udpcuatro[0].IntCodigo : '', "strNombre_UDP": udpcuatro[0] ? udpcuatro[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpcuatroselected : '', "strCinta_UDP": udpcuatro[0] ? nameCintacuatro : '', "intCantidadTotal_UDP": udpcuatro[0] ? cantudpcuatroselected : '' }
                ]
            }
            //Colocar Guantelete
            if (concepto.IntCodigo == 451) {
                dataRdt['strUdp'] = [
                    { "IntCodigo_UDP": udpuno[0] ? udpuno[0].IntCodigo : '', "strNombre_UDP": udpuno[0] ? udpuno[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpunoselected : '', "strCinta_UDP": udpuno[0] ? nameCinta : '', "intCantidadTotal_UDP": udpuno[0] ? cantudpunoselected : '' },
                    { "IntCodigo_UDP": udpdos[0] ? udpdos[0].IntCodigo : '', "strNombre_UDP": udpdos[0] ? udpdos[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpdosselected : '', "strCinta_UDP": udpdos[0] ? nameCintados : '', "intCantidadTotal_UDP": udpdos[0] ? cantudpdosselected : '' },
                    { "IntCodigo_UDP": udptres[0] ? udptres[0].IntCodigo : '', "strNombre_UDP": udptres[0] ? udptres[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudptresselected : '', "strCinta_UDP": udptres[0] ? nameCintatres : '', "intCantidadTotal_UDP": udptres[0] ? cantudptresselected : '' },
                    { "IntCodigo_UDP": udpcuatro[0] ? udpcuatro[0].IntCodigo : '', "strNombre_UDP": udpcuatro[0] ? udpcuatro[0].strDescripcion : '', "idCinta_UDP": udpuno[0] ? cintaudpcuatroselected : '', "strCinta_UDP": udpcuatro[0] ? nameCintacuatro : '', "intCantidadTotal_UDP": udpcuatro[0] ? cantudpcuatroselected : '' }
                ]
            }
            //console.log({datosRDT: dataRdt})
            //console.log({Fecha: fecha})

            const response = await requestHttp(
                {
                    endpoint: '/rdts',
                    body: dataRdt
                }
            );
            /*setAlert({
                msg: response.data,
                error: false
            })*/
            await getRdts();

            if (data.checkbox[0] === 'A') {
                //console.log('estoy lleno')
                setMultilabor(1);
                setConceptoSelected('');
                getRdtstrabajador();
                setHoras(0)


            } else {
                //console.log('estoy vacio')
                //setCodigoSelected('');                
                setUdpunoselected(0);
                //setEmploye([]);
                setConcepto([]);
                setUdpuno([]);
                setUdpdos([]);
                setUdptres([]);
                setUdpcuatro([]);
                setMultilabor(0);

            }
            showAlert(
                'Grabado',
                "Correctamente",
                SW_ICON.SUCCESS,
                () => { FocusOnInput() }
            );
            /*setTimeout(()=>{
                setAlert({})                
            }, 7000)*/
        } catch (error) {
            console.log(error)
            setAlert({
                msg: error.response.data,
                error: true
            })
            setTimeout(() => {
                setAlert({})
            }, 10000)
        }

    }
    //Busqueda por Nombre
    const employeesFiltrados = busqueda === '' ? [] : employees.filter(employe => employe.strNombreTrabajador.toLowerCase().includes(busqueda.toLowerCase()))
    //console.log(employeesFiltrados)
    const { msg } = alert;

    function FocusOnInput() {
        document.getElementById("input1").focus();
    }
    function FocusOnInputlabor() {
        document.getElementById("input2").focus();
    }
    //Validar dia de la semana
    const fechaComoCadena = date + " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    const rango = new Date(hoy - fecha);
    //Dos dias para registrar
    //const tiempo = new Date(189999999);
    //Cuatro dias para registrar
    //const tiempoViernes = new Date(363599999);
    //Tres dias para registrar
    //const tiempoSabado = new Date(276999999);

    //Recuperar color de cinta para labor 118 embolsePm
    if (calendar.length) {
        const color = colors.find(color => color.id == calendar[0].cintaPm)
        if (color) {
            cintaPm = color.cinta
            nameCintaPm = color.name
            //console.log(cintaPm)
        } else {
            cintaPm = 0
            nameCintaPm = ''
        }
        //console.log(color)        
    } else {
        cintaPm = 0
        nameCintaPm = ''
    }
    //Recuperar color de cinta para labor 118 embolsePs
    if (calendar.length) {
        const color = colors.find(color => color.id == calendar[0].cintaPs)
        if (color) {
            cintaPs = color.cinta
            nameCintaPs = color.name
            //console.log(cintaPm)
        } else {
            cintaPs = 0
            nameCintaPs = ''
        }
        //console.log(color)        
    } else {
        cintaPs = 0
        nameCintaPs = ''
    }
    //Recuperar color de cinta para labor 119 colocar guantelete udpuno
    if (cintaudpunoselected > 0) {
        const color = colors.find(color => color.id == cintaudpunoselected)
        if (color) {
            cinta = color.cinta
            nameCinta = color.name
            nameCintaCorto = color.nameCorto
            //console.log(cinta)
        } else {
            cinta = 'transparent'
            nameCinta = ''
            nameCintaCorto = ''
        }
        //console.log(color)        
    } else {
        cinta = 'transparent'
        nameCinta = ''
        nameCintaCorto = ''
    }
    //Recuperar color de cinta para labor 119 colocar guantelete udpdos
    if (cintaudpdosselected > 0) {
        const color = colors.find(color => color.id == cintaudpdosselected)
        if (color) {
            cintados = color.cinta
            nameCintados = color.name
            nameCintadosCorto = color.nameCorto
            //console.log(cintados)
        } else {
            cintados = 'transparent'
            nameCintados = ''
            nameCintadosCorto = ''

        }
        //console.log(color)        
    } else {
        cintados = 'transparent'
        nameCintados = ''
        nameCintadosCorto = ''
    }
    //Recuperar color de cinta para labor 119 colocar guantelete udptres
    if (cintaudptresselected > 0) {
        const color = colors.find(color => color.id == cintaudptresselected)
        if (color) {
            cintatres = color.cinta
            nameCintatres = color.name
            nameCintatresCorto = color.nameCorto
            //console.log(cintados)
        } else {
            cintatres = 'transparent'
            nameCintatres = ''
            nameCintatresCorto = ''
        }
        //console.log(color)        
    } else {
        cintatres = 'transparent'
        nameCintatres = ''
        nameCintatresCorto = ''
    }
    //Recuperar color de cinta para labor 119 colocar guantelete udpcuatro
    if (cintaudpcuatroselected > 0) {
        const color = colors.find(color => color.id == cintaudpcuatroselected)
        if (color) {
            cintacuatro = color.cinta
            nameCintacuatro = color.name
            nameCintacuatroCorto = color.nameCorto
            //console.log(cintados)
        } else {
            cintacuatro = 'transparent'
            nameCintacuatro = ''
            nameCintacuatroCorto = ''
        }
        //console.log(color)        
    } else {
        cintacuatro = 'transparent'
        nameCintacuatro = ''
        nameCintacuatroCorto = ''
    }



    //console.log({rango: rango})
    //console.log({tiempo: tiempo})
    //console.log({tiempoViernes: tiempoViernes})
    //console.log({tiempoSabado: tiempoSabado})
    //console.log({numeroDiaRDT: numeroDia})
    //console.log({fecha: fecha})
    //console.log({tiempoTranscurrido: tiempoTranscurrido})   
    //console.log("Fecha actual: ", hoy); 
    //console.log("Calendario: ", fecha);   
    //console.log("nombre dia: ", nombreDia);
    //console.log("Concepto: ", concepto); 

    return (
        <FormControlRdtsDistribution>
            <CerrarRdtsDistribution>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarRdtsDistribution} />
            </CerrarRdtsDistribution>
            {animarModald ?
                <Page>
                    <AddRdtsWrapper_Global>
                        <AddRdtsWrapper_Global_DOS>
                            {/**Logo y Titulo */}
                            <LogoImageWrapper
                                rdt={'si'}
                            >
                                <PropertyGraf
                                    ibm={sucursalSeleted}
                                    fecha={date}
                                />
                                <PageTitle>
                                    REGISTRAR RDTs
                                </PageTitle>
                                <img src={"Logodos.jpg"} alt="imgLogo" />
                            </LogoImageWrapper>
                            {/*Finca y Fecha*/}
                            <FormControlSeccionOne>
                                <FormControl>
                                    <FormControlInputSearch>
                                        <label>FINCA</label>
                                        <select id="sucursal"
                                            {...register("sucursal")}
                                            onChange={handleChange}
                                            tabIndex="1"
                                            value={sucursalSeleted}
                                            disabled={true}>
                                            <option value={""}>--Seleccione--</option>
                                            {sucursal.map(item => (
                                                <option key={item.strCodigo}
                                                    value={item.strCodigo}
                                                    id={item.strCodigo}
                                                >{item.strNombre}</option>
                                            )
                                            )
                                            }
                                        </select>
                                    </FormControlInputSearch>

                                    <FormControlInputSearch>
                                        <label>FECHA DE RDT</label>
                                        <input type="date"
                                            {...register("fechardt")}
                                            value={date}
                                            disabled={true}
                                            onChange={handleChangedate}
                                            tabIndex="2"
                                        />
                                        {errors.fechardt?.type === 'required' && <span>Este Campo es requerido</span>}
                                    </FormControlInputSearch>
                                </FormControl>
                                <FormControl>
                                    <FormControlTitle>
                                        {dial[0] ?
                                            <label>{sucursalname}</label>
                                            :
                                            <AddRdtsFillnofoundFarm>
                                                <label>{sucursalname}</label>
                                                <ButtonIcon icon={IoHomeSharp} onPress={handleAutorizacionFarm} />
                                            </AddRdtsFillnofoundFarm>}
                                    </FormControlTitle>
                                    <FormControlTitle>
                                        <span>
                                            {nombreDia}
                                            <br />
                                            {date}
                                        </span>
                                    </FormControlTitle>
                                </FormControl>
                            </FormControlSeccionOne>
                            <form >
                                {/*Datos Trabajador*/}
                                <FormControlSeccionOne>
                                    <FormControl>
                                        <FormControlInputSearch>
                                            <label>COD. TRABAJADOR</label>
                                            <input type="number"
                                                {...register("codigoSearch")}
                                                value={codigoSeleted}
                                                onChange={e => setCodigoSelected(e.target.value)}
                                                disabled={true}
                                                tabIndex="3"
                                                id="input1"
                                            />
                                        </FormControlInputSearch>

                                        <FormControlInput>
                                            <label>CODIGO</label>
                                            <p {...register("codigo")}>{employe[0] ? employe[0].IntCodigo : ''}</p>
                                        </FormControlInput>
                                    </FormControl>
                                    <FormControl>
                                        <FormControlInput>
                                            <label>NOMBRE DEL TRABAJADOR</label>
                                            {dial[0] ?
                                                <AddRdtsFill>
                                                    <p {...register("name")}>{employe[0] ? employe[0].strNombreTrabajador : ''}</p>
                                                </AddRdtsFill> :
                                                <AddRdtsFillnofound>
                                                    <p {...register("name")}>{employe[0] ? employe[0].strNombreTrabajador : ''}</p>
                                                    <ButtonIcon icon={IoAccessibility} onPress={handleAutorizacionEmploye} />
                                                    {modal && <AuthorizationsRdts
                                                        setModal={setModal}
                                                        animarModal={animarModal}
                                                        employe={employe}
                                                    />
                                                    }
                                                </AddRdtsFillnofound>}
                                        </FormControlInput>
                                    </FormControl>
                                    <FormControl>

                                        <FormControlInput>
                                            <label>FINCA A LA QUE PERTENECE</label>
                                            {dial[0] ?
                                                <p {...register("origen")}>{employe[0] ? employe[0].strCodigoCentro : ''}</p>
                                                :
                                                <AddRdtsFillnofoundFarm>
                                                    <p {...register("origen")}>{employe[0] ? employe[0].strCodigoCentro : ''}</p>
                                                    {modal && <AuthorizationsRdts
                                                        setModal={setModal}
                                                        animarModal={animarModal}
                                                        setAnimarModal={setAnimarModal}
                                                    />
                                                    }
                                                </AddRdtsFillnofoundFarm>}
                                        </FormControlInput>
                                    </FormControl>
                                </FormControlSeccionOne>
                                {/*Cod Labor y Exige UDP*/}
                                <FormControlSeccionOne>
                                    <FormControl>
                                        {concepto.intEXUDP === 1 ?
                                            <FormControlInput>
                                                <label>=UDP</label>
                                                <input {...register("checkbox")}
                                                    type="checkbox" value="A"
                                                />
                                            </FormControlInput> : multilabor === 1 ?
                                                <FormControlInput>
                                                    <label>=UDP</label>
                                                    <input {...register("checkbox")}
                                                        type="checkbox" value="A"
                                                    />
                                                </FormControlInput> : ''}
                                        <FormControlInputSearch>
                                            <label>COD.LABOR</label>
                                            <input type="number"
                                                {...register("codigoSearchLabor")}
                                                value={conceptoSeleted}
                                                onChange={handleChangeCodLabor}
                                                autoFocus
                                                tabIndex="4"
                                                id="input2"
                                            />
                                        </FormControlInputSearch>
                                        <FormControl>
                                            <FormControlInput>
                                                <label>LABOR:</label>
                                                <p  {...register("codlabor")}>{concepto.strCadena ? concepto.strCadena : ''}</p>
                                            </FormControlInput>
                                        </FormControl>
                                    </FormControl>
                                    <FormControl>
                                        <FormControl>
                                            <FormControlInput>
                                                <label>U.MED</label>
                                                <p  {...register("umedidad")}>{concepto.strUMedida ? concepto.strUMedida : ''}</p>
                                            </FormControlInput>
                                            <FormControlInput>
                                                <label>VUELT</label>
                                                <p  {...register("vueltas")}>{concepto.intVueltas ? concepto.intVueltas : ''}</p>
                                            </FormControlInput>
                                            <FormControlInput>
                                                <label>CICLO</label>
                                                <p  {...register("ciclo")}>{concepto.intCiclo ? concepto.intCiclo : ''}</p>
                                            </FormControlInput>

                                            <FormControlInput>
                                                <label>E.UDP</label>
                                                <p {...register("exudp")}>{concepto.intEXUDP ? concepto.intEXUDP : ''}</p>
                                            </FormControlInput>
                                        </FormControl>
                                    </FormControl>
                                </FormControlSeccionOne>
                                {/*Tabla para digitar UDPs*/}
                                {concepto.intEXUDP ?
                                    <FormControl>
                                        <table>
                                            {/**Encabezado UPDs */}
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormControlInputSearch>
                                                            <label>UDP</label>
                                                        </FormControlInputSearch>
                                                    </th>
                                                    <th>
                                                        <FormControlInputSearch>
                                                            <label>CANTIDAD</label>
                                                        </FormControlInputSearch>
                                                    </th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControlHead>
                                                            <th>
                                                                <FormControlInputTitel>
                                                                    <label>PREM</label>
                                                                    <ButtonIconRibbon icon={IoBookmark} cinta={cintaPm} />
                                                                </FormControlInputTitel>
                                                            </th>
                                                            <th>
                                                                <FormControlInputTitel>
                                                                    <label>PRES</label>
                                                                    <ButtonIconRibbon icon={IoBookmark} cinta={cintaPs} />
                                                                </FormControlInputTitel>
                                                            </th>
                                                            <th>
                                                                <FormControlInputTitel>
                                                                    <label>PM+PS</label>
                                                                </FormControlInputTitel>
                                                            </th>
                                                        </FormControlHead>
                                                        : ''}
                                                    {concepto.IntCodigo == 119 || concepto.IntCodigo == '451' ?
                                                        <FormControlHead>
                                                            <th>
                                                                <FormControlInputTitel>
                                                                    <label>CINTA</label>
                                                                </FormControlInputTitel>
                                                            </th>

                                                        </FormControlHead>
                                                        : ''}

                                                    <th>
                                                        <FormControlInputudp>
                                                            <label>A.NETA</label>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <label>DESCRIPCIÓN</label>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <label>A.BRUTA</label>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <label>LOTE</label>
                                                        </FormControlInputudp>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/**UDP 1 */}
                                                <tr>
                                                    <th>
                                                        <FormControlInputSearchudp>
                                                            <input type="number"
                                                                {...register("codigoSearchudpuno")}
                                                                value={udpunoselected}
                                                                onChange={e => setUdpunoselected(e.target.value)}
                                                                tabIndex="5"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputSearchudp>
                                                            <input type="number"
                                                                {...register("cantudpuno")}
                                                                value={cantudpunoselected}
                                                                onChange={e => setCantudpunoselected(e.target.value)}
                                                                tabIndex="6"
                                                                step="any" // Permite decimales
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControl>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpunoPrem")}
                                                                        value={cantudpunoPmselected}
                                                                        onChange={e => setCantudpunoPmselected(e.target.value)}
                                                                        tabIndex="6"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpunoPres")}
                                                                        value={cantudpunoPsselected}
                                                                        onChange={e => setCantudpunoPsselected(e.target.value)}
                                                                        tabIndex="6"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpunoPP")}
                                                                        value={cantudpunoPPselected}
                                                                        onChange={e => setCantudpunoPPselected(e.target.value)}
                                                                        tabIndex="6"
                                                                        disabled={concepto.IntCodigo == 118 ? true : false}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                        </FormControl>
                                                        : ''}
                                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451' ?
                                                        <FormControlInputSearchcinta>
                                                            <td>
                                                                <input type="number"
                                                                    {...register("cintaudpuno")}
                                                                    value={cintaudpunoselected}
                                                                    onChange={e => setCintaudpunoselected(e.target.value)}
                                                                    tabIndex="6"
                                                                    onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                    step="any" // Permite decimales
                                                                    onKeyDown={(e) => {
                                                                        // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                        if (
                                                                            ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                            // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                            (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                        ) {
                                                                            return;
                                                                        }

                                                                        // Bloquea todo lo que no sea número
                                                                        if (!/[0-9]/.test(e.key)) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <ButtonIconRibbon icon={IoBookmark} cinta={cinta} />
                                                            </td>
                                                            <td>
                                                                {nameCintaCorto}
                                                            </td>
                                                        </FormControlInputSearchcinta>

                                                        : ''}

                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("anetauno")}><span>{udpuno[0] ? udpuno[0].intANeta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("descriptionuno")}>{udpuno[0] ? udpuno[0].strDescripcion : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("abrutauno")}><span>{udpuno[0] ? udpuno[0].intABruta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("codigoudpnuno")}>{udpuno[0] ? udpuno[0].IntCodigo : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>

                                                </tr>
                                                {/**UDP 2 */}
                                                <tr>
                                                    <th>
                                                        <FormControlInputSearchudp>

                                                            <input type="number"
                                                                {...register("codigoSearchudpdos")}
                                                                value={udpdosselected}
                                                                onChange={e => setUdpdosselected(e.target.value)}
                                                                tabIndex="7"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputSearchudp>
                                                            <input type="number"
                                                                {...register("cantudpdos")}
                                                                value={cantudpdosselected}
                                                                onChange={e => setCantudpdosselected(e.target.value)}
                                                                tabIndex="7"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControl>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpdosPrem")}
                                                                        value={cantudpdosPmselected}
                                                                        onChange={e => setCantudpdosPmselected(e.target.value)}
                                                                        tabIndex="7"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpdosPres")}
                                                                        value={cantudpdosPsselected}
                                                                        onChange={e => setCantudpdosPsselected(e.target.value)}
                                                                        tabIndex="7"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpdosPP")}
                                                                        value={cantudpdosPPselected}
                                                                        onChange={e => setCantudpdosPPselected(e.target.value)}
                                                                        tabIndex="8"
                                                                        disabled={concepto.IntCodigo == 118 ? true : false}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                        </FormControl>
                                                        : ''}
                                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451' ?
                                                        <FormControlInputSearchcinta>
                                                            <td>
                                                                <input type="number"
                                                                    {...register("cintaudpdos")}
                                                                    value={cintaudpdosselected}
                                                                    onChange={e => setCintaudpdosselected(e.target.value)}
                                                                    tabIndex="8"
                                                                    onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                    step="any" // Permite decimales
                                                                    onKeyDown={(e) => {
                                                                        // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                        if (
                                                                            ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                            // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                            (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                        ) {
                                                                            return;
                                                                        }

                                                                        // Bloquea todo lo que no sea número
                                                                        if (!/[0-9]/.test(e.key)) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <ButtonIconRibbon icon={IoBookmark} cinta={cintados} />
                                                            </td>
                                                            <td>
                                                                {nameCintadosCorto}
                                                            </td>
                                                        </FormControlInputSearchcinta>

                                                        : ''}
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("anetados")}><span>{udpdos[0] ? udpdos[0].intANeta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("descriptiondos")}>{udpdos[0] ? udpdos[0].strDescripcion : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("abrutados")}><span>{udpdos[0] ? udpdos[0].intABruta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("codigoudpndos")}>{udpdos[0] ? udpdos[0].IntCodigo : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>

                                                </tr>
                                                {/**UDP 3 */}
                                                <tr>
                                                    <th>
                                                        <FormControlInputSearchudp>

                                                            <input type="number"
                                                                {...register("codigoSearchudptres")}
                                                                value={udptresselected}
                                                                onChange={e => setUdptresselected(e.target.value)}
                                                                tabIndex="9"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputSearchudp>
                                                            <input type="number"
                                                                {...register("cantudptres")}
                                                                value={cantudptresselected}
                                                                onChange={e => setCantudptresselected(e.target.value)}
                                                                tabIndex="9"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControl>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudptresPrem")}
                                                                        value={cantudptresPmselected}
                                                                        onChange={e => setCantudptresPmselected(e.target.value)}
                                                                        tabIndex="9"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudptresPres")}
                                                                        value={cantudptresPsselected}
                                                                        onChange={e => setCantudptresPsselected(e.target.value)}
                                                                        tabIndex="9"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudptresPP")}
                                                                        value={cantudptresPPselected}
                                                                        onChange={e => setCantudptresPPselected(e.target.value)}
                                                                        tabIndex="10"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        disabled={concepto.IntCodigo == 118 ? true : false}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                        </FormControl>
                                                        : ''}
                                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451' ?
                                                        <FormControlInputSearchcinta>
                                                            <td>
                                                                <input type="number"
                                                                    {...register("cintaudptres")}
                                                                    value={cintaudptresselected}
                                                                    onChange={e => setCintaudptresselected(e.target.value)}
                                                                    tabIndex="9"
                                                                    onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                    step="any" // Permite decimales
                                                                    onKeyDown={(e) => {
                                                                        // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                        if (
                                                                            ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                            // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                            (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                        ) {
                                                                            return;
                                                                        }

                                                                        // Bloquea todo lo que no sea número
                                                                        if (!/[0-9]/.test(e.key)) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <ButtonIconRibbon icon={IoBookmark} cinta={cintatres} />
                                                            </td>
                                                            <td>
                                                                {nameCintatresCorto}
                                                            </td>
                                                        </FormControlInputSearchcinta>

                                                        : ''}
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("anetatres")}><span>{udptres[0] ? udptres[0].intANeta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p  {...register("descriptiontres")}>{udptres[0] ? udptres[0].strDescripcion : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("abrutatres")}><span>{udptres[0] ? udptres[0].intABruta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("codigoudpntres")}>{udptres[0] ? udptres[0].IntCodigo : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>

                                                </tr>
                                                {/**UDP 4 */}
                                                <tr>
                                                    <th>
                                                        <FormControlInputSearchudp>

                                                            <input type="number"
                                                                {...register("codigoSearchudpcuatro")}
                                                                value={udpcuatroselected}
                                                                onChange={e => setUdpcuatroselected(e.target.value)}
                                                                tabIndex="11"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputSearchudp>
                                                            <input type="number"
                                                                {...register("cantudpcuatro")}
                                                                value={cantudpcuatroselected}
                                                                onChange={e => setCantudpcuatroselected(e.target.value)}
                                                                tabIndex="11"
                                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                step="any" // Permite decimales
                                                                onKeyDown={(e) => {
                                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                    if (
                                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    // Bloquea todo lo que no sea número
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </FormControlInputSearchudp>
                                                    </th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControl>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpcuatroPrem")}
                                                                        value={cantudpcuatroPmselected}
                                                                        onChange={e => setCantudpcuatroPmselected(e.target.value)}
                                                                        tabIndex="11"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpcuatroPres")}
                                                                        value={cantudpcuatroPsselected}
                                                                        onChange={e => setCantudpcuatroPsselected(e.target.value)}
                                                                        tabIndex="11"
                                                                        onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                        step="any" // Permite decimales
                                                                        onKeyDown={(e) => {
                                                                            // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                            if (
                                                                                ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                                // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                                (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                            ) {
                                                                                return;
                                                                            }

                                                                            // Bloquea todo lo que no sea número
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                            <th>
                                                                <FormControlInputSearchudp>
                                                                    <input type="number"
                                                                        {...register("cantudpcuatroPP")}
                                                                        value={cantudpcuatroPPselected}
                                                                        onChange={e => setCantudpcuatroPPselected(e.target.value)}
                                                                        tabIndex="12"
                                                                        disabled={concepto.IntCodigo == 118 ? true : false}
                                                                    />
                                                                </FormControlInputSearchudp>
                                                            </th>
                                                        </FormControl>
                                                        : ''}
                                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451' ?
                                                        <FormControlInputSearchcinta>
                                                            <td>
                                                                <input type="number"
                                                                    {...register("cintaudpcuatro")}
                                                                    value={cintaudpcuatroselected}
                                                                    onChange={e => setCintaudpcuatroselected(e.target.value)}
                                                                    tabIndex="11"
                                                                    onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                                    step="any" // Permite decimales
                                                                    onKeyDown={(e) => {
                                                                        // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                                        if (
                                                                            ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) ||
                                                                            // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                                            (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                                        ) {
                                                                            return;
                                                                        }

                                                                        // Bloquea todo lo que no sea número
                                                                        if (!/[0-9]/.test(e.key)) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <ButtonIconRibbon icon={IoBookmark} cinta={cintacuatro} />
                                                            </td>
                                                            <td>
                                                                {nameCintacuatroCorto}
                                                            </td>
                                                        </FormControlInputSearchcinta>

                                                        : ''}
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("anetacuatro")}><span>{udpcuatro[0] ? udpcuatro[0].intANeta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("descriptioncuatro")}>{udpcuatro[0] ? udpcuatro[0].strDescripcion : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("abrutacuatro")}><span>{udpcuatro[0] ? udpcuatro[0].intABruta : ''}</span></p>
                                                        </FormControlInputudp>
                                                    </th>
                                                    <th>
                                                        <FormControlInputudp>
                                                            <p {...register("codigoudpncuatro")}>{udpcuatro[0] ? udpcuatro[0].IntCodigo : ''}</p>
                                                        </FormControlInputudp>
                                                    </th>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Total:</th>
                                                    <th>{sumcantidad.toFixed(2)}</th>
                                                    {concepto.IntCodigo == 118 ?
                                                        <FormControlHead>
                                                            <th>{sumcantPm.toFixed(0)}</th>
                                                            <th>{sumcantPs.toFixed(0)}</th>
                                                            <th>{sumcantPP.toFixed(0)}</th>
                                                        </FormControlHead>
                                                        : ''}

                                                </tr>
                                            </tfoot>
                                        </table>
                                    </FormControl> : ''}
                                {/*Mensaje y Dig Horas, cant, observ, botón grabar*/}
                                {msg && <Alert alert={alert} />}
                                <FormControlSeccionOne>
                                    <FormControlSeccionTwo>
                                        <FormControlInputSearch>
                                            <label>HORAS_LAB</label>
                                            <input type="number"
                                                {...register("horas")}
                                                value={horas}
                                                onChange={e => setHoras(e.target.value)}
                                                tabIndex="13"
                                                id="input3"
                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                step="any" // Permite decimales
                                                onKeyDown={(e) => {
                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                    if (
                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                    ) {
                                                        return;
                                                    }

                                                    // Bloquea todo lo que no sea número
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </FormControlInputSearch>
                                        <FormControlInputSearch>
                                            <label>CANT._TOTAL</label>
                                            <input type="number"
                                                {...register("total")}
                                                value={sumcantidad}
                                                onChange={e => setSumcantidad(e.target.value)}
                                                disabled={concepto.intEXUDP === 1 ? true : false}
                                                tabIndex="14"
                                                id="input4"
                                                onFocus={(e) => e.target.select()} // Selecciona el texto automáticamente
                                                step="any" // Permite decimales
                                                onKeyDown={(e) => {
                                                    // Permite: backspace, delete, flechas, tab, punto (.) y coma (,)
                                                    if (
                                                        ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','].includes(e.key) ||
                                                        // Permite: Ctrl+C, Ctrl+V, Ctrl+X
                                                        (e.ctrlKey && ['c', 'v', 'x'].includes(e.key.toLowerCase()))
                                                    ) {
                                                        return;
                                                    }

                                                    // Bloquea todo lo que no sea número
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </FormControlInputSearch>
                                    </FormControlSeccionTwo>

                                    <FormControl>
                                        <FormControlInputSearch>
                                            <label>OBSERVACIÓN</label>
                                            <input type="text"
                                                {...register("observacion")}
                                                value={observacion}
                                                onChange={e => setObservacion(e.target.value)}
                                                tabIndex="15"                                                
                                                id="input5"
                                                //onKeyDown={(e) => handleKeyDown(e, 15)}

                                            />
                                        </FormControlInputSearch>
                                    </FormControl>
                                    <FormControl>
                                        {modal ? '' : <Button                                                       
                                                        label="GRABAR"                                                         
                                                        onPress={handleSubmit(onSubmitsave)} />}
                                    </FormControl>
                                </FormControlSeccionOne>
                            </form>
                            {/*Inf que he digitado*/}
                            <PropertyTypesContainerRow>
                                <PropertyTypesContainerColum>
                                    <label>Ultimos registros que he digitado...</label>
                                    {rdts.map((item, key) =>
                                        <PropertyRdtsDistribution
                                            key={key}
                                            {...item}
                                            propertyTypeSeleted
                                            setModalD={setModalD}
                                            animarModald={animarModald}
                                        />
                                    )
                                    }
                                </PropertyTypesContainerColum>
                                <PropertyTypesContainerColum>
                                    <label>Rdts digitados para el trabajador:</label>
                                    <label>
                                        <p>
                                            {rdtstrabajador[0] ? rdtstrabajador[0].strNombreTrabajador : ''}&nbsp;
                                            {rdtstrabajador[0] ? 'Cod:' : ''}
                                            {rdtstrabajador[0] ? rdtstrabajador[0].IntCodigo_EMPL : ''}&nbsp;
                                        </p>

                                    </label>
                                    {rdtstrabajador.map((item, key) =>
                                        <>
                                            <PropertyRdtstrabajadorDistribution
                                                key={key}
                                                {...item}
                                                propertyTypeSeleted
                                                setModalD={setModalD}
                                                animarModald={animarModald}
                                            />
                                        </>
                                    )
                                    }
                                </PropertyTypesContainerColum>
                            </PropertyTypesContainerRow>
                            <br />
                            <br />
                            {/*Buscar Trabajador*/}
                            <FormControl>
                                <FormControlInputSearch>
                                    <AddRdtsCombo>
                                        <Combobox
                                            as="div"
                                            onChange={(employe) => (setEmploye([employe]))}
                                        >
                                            <div className="relative">
                                                <Combobox.Input
                                                    placeholder="Buscar por nombre de trabajador..."
                                                    onChange={e => setBusqueda(e.target.value)}
                                                    id="input8"
                                                />
                                            </div>

                                            {employeesFiltrados.length > 0 && (
                                                <Combobox.Options static >
                                                    {employeesFiltrados.map((employe, key) =>
                                                        <AddRdtsBusqueda key={employe.idTrabajador}>
                                                            <Combobox.Option
                                                                value={employe}
                                                            >
                                                                {employe.strNombreTrabajador + '  ' + '( ' + employe.IntCodigo + ' - ' + employe.strCodigoCentro + ' )'}
                                                            </Combobox.Option>
                                                        </AddRdtsBusqueda>
                                                    )}
                                                </Combobox.Options>
                                            )}
                                        </Combobox>
                                    </AddRdtsCombo>
                                </FormControlInputSearch>
                            </FormControl>
                        </AddRdtsWrapper_Global_DOS>
                    </AddRdtsWrapper_Global>
                </Page>

                : ''}
        </FormControlRdtsDistribution>
    )
}