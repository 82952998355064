import styled from "styled-components";

export const FormControlGraft = styled.div`
    border: 1px solid blue;   
    width: 17%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;        

    //Para Tablet
    @media (max-width: 1280px){
        //border: 1px solid blue;
        display: none;
    } 
`;

export const FormControl = styled.div`
    //border: 1px solid blue;      
    margin: 5px;
    border-radius: 6px;
    background-color: #fff;
    padding: 5px 5px;
    border: 1px solid #dfdfdf;
    
    
    label{
        //display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        span{
            margin-left:5px;
            color: black;              
        }                    
    }      
    
    //Para Tablet
    @media (max-width: 1280px){
        //border: 1px solid blue;
        display: none;
        margin: 0;
        padding: 0;
    }
`;